import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: 'Golos', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.3;
  }

  // TODO: remove when date-picker styles get update
  body .MuiPickersCalendarHeader-switchHeader .MuiSvgIcon-root path:last-child {
    fill: none;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  *::-webkit-scrollbar {
      width: 0.5em;
      height: 0.5em;
    }

  *::-webkit-scrollbar-track {
    background-color: #F2F2F2;
    border-radius: 4px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #d9dcde;
    border-radius: 4px;
  }
`;

export default GlobalStyle;
