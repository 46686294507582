import { all, call } from 'redux-saga/effects';

import { commentsSagas } from './comments/comments-sagas';
import { contractSagas } from './contract/contract-sagas';
import { employeeSagas } from './employee/employee-sagas';
import { orgStructureSagas } from './org-structure/org-structure-sagas';
import { timesheetSagas } from './timesheets/timesheets-sagas';

export default function* rootSaga() {
  yield all([
    call(timesheetSagas),
    call(orgStructureSagas),
    call(commentsSagas),
    call(employeeSagas),
    call(contractSagas),
  ]);
}
