import { AlertColor } from '@mui/lab/Alert';

import { PayloadAction } from '@reduxjs/toolkit';

import { FetchingState, LoadingState } from './loading-slice';

// TODO: unite with UI reducer
const reducers = {
  setFetchingStart: (state: LoadingState, action: PayloadAction<string>) => {
    state.fetching = {
      ...state.fetching,
      [action.payload]: 'PENDING',
    };
    state.error = {
      ...state.error,
      [action.payload]: '',
    };
  },
  setFetchingSuccess: (state: LoadingState, action: PayloadAction<string>) => {
    state.fetching = {
      ...state.fetching,
      [action.payload]: 'IDLE',
    };
    state.error = {
      ...state.error,
      [action.payload]: '',
    };
  },
  setFetchingFailure: (state: LoadingState, action: PayloadAction<string>) => {
    state.fetching = {
      ...state.fetching,
      [action.payload]: 'FAILURE',
    };
  },
  clearFetchingStatus: (state: LoadingState, action: PayloadAction<string>) => {
    const fetchingStatuses = { ...state.fetching };
    delete fetchingStatuses[action.payload];

    state.fetching = { ...fetchingStatuses };
  },
  setFetchingStatus: (state: LoadingState, action: PayloadAction<{ type: string; status: FetchingState }>) => {
    const { type, status } = action.payload;

    state.fetching = {
      ...state.fetching,
      [type]: status,
    };
  },
  setError: (state: LoadingState, action: PayloadAction<{ actionType: string; message: string }>) => {
    const { actionType, message } = action.payload;

    state.error = { ...state.error, [actionType]: message };
  },
  clearError: (state: LoadingState, action: PayloadAction<string>) => {
    state.error = { ...state.error, [action.payload]: '' };
  },
  openSnackbar: (state: LoadingState, action: PayloadAction<{ severity: AlertColor; message: string }>) => {
    const { severity, message } = action.payload;
    state.snackbarOpen = true;
    state.snackbarSeverity = severity;
    state.snackbarMessage = message;
  },
  closeSnackbar: (state: LoadingState, action: PayloadAction<string>) => {
    state.snackbarOpen = false;
    state.snackbarMessage = '';
  },
};

export default reducers;
