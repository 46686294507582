/* eslint-disable @typescript-eslint/no-empty-function */
import { PayloadAction } from '@reduxjs/toolkit';

import { TimesheetsState } from './timesheets-slice';
import {
  addProjectData,
  changeWeekData,
  deleteProjectData,
  fetchTablesData,
  retrieveProjects,
  retrieveWeeks,
} from './utils';

const reducers = {
  setTranslations: (state: TimesheetsState, action: PayloadAction<Record<string, string>>) => {
    state.translations = action.payload;
  },
  fetchMonthByIdStart: (state: TimesheetsState, action: PayloadAction<Record<string, any>>) => {
    state.currentStatistics = null;
    state.currentStatus = null;
    state.currentDays = [];
    state.currentWeeks = null;
    state.currentProjects = null;
    state.tablesData = [];
    state.notifications = null;
  },
  fetchMonthByIdSuccess: (state: TimesheetsState, action: PayloadAction<any>) => {
    const { days, hours, status, name, title, employeeStatus, hasErrors, verified, month, year } = action.payload;
    const weeks = retrieveWeeks(days);
    const projects = retrieveProjects(weeks);
    const tablesData = fetchTablesData(weeks, projects, { ...state.translations });

    state.currentMonth = month.toString();

    state.currentStatistics = hours;
    state.currentStatus = status;
    state.currentDays = days;
    state.currentWeeks = weeks;
    state.currentProjects = projects;
    state.tablesData = tablesData;
    state.temp = { ...state.temp, name, title, employeeStatus, hasErrors, verified, month, year };
  },
  setEditMode: (state: TimesheetsState, action: PayloadAction<boolean>) => {
    state.editModeOn = action.payload;
  },
  saveTimesheetStart: (
    state: TimesheetsState,
    action: PayloadAction<{
      id: string;
      data: any;
      companyId?: string;
      employeeId?: string;
      success: string;
      error: string;
    }>
  ) => {},
  saveTimesheetSuccess: (state: TimesheetsState, action: PayloadAction<any>) => {
    const weeks = retrieveWeeks(action.payload.days);
    const projects = retrieveProjects(weeks);
    const tablesData = fetchTablesData(weeks, projects, { ...state.translations });

    state.editModeOn = false;
    state.currentStatistics = action.payload.hours;
    state.currentStatus = action.payload.status;
    state.currentDays = action.payload.days;
    state.currentWeeks = weeks;
    state.currentProjects = projects;
    state.tablesData = tablesData;
  },
  setTimesheetStatusStart: (
    state: TimesheetsState,
    action: PayloadAction<{ id: string; data: { status: any }; companyId?: string; employeeId?: string }>
  ) => {},
  setTimesheetStatusSuccess: (state: TimesheetsState, action: PayloadAction<any>) => {
    state.currentStatus = action.payload.status;
  },
  addProject: (state: TimesheetsState, action: PayloadAction<{ weekNumber: string; projectId: string }>) => {
    const { projectId } = action.payload;
    const currentWeeks = state.currentWeeks;
    const tablesData = state.tablesData;
    const newData = addProjectData(currentWeeks, tablesData, projectId);

    state.tablesData = newData;
  },

  setCurrentProjects: (state: TimesheetsState, action: PayloadAction<{ weekNumber: string; projectId: string }>) => {
    const { weekNumber, projectId } = action.payload;
    state.currentProjects[weekNumber].push({ projectId, typeOfWork: '' });
  },

  setWeekProjects: (state: TimesheetsState, action: PayloadAction<{ weekNumber: string; projects: string[] }>) => {
    const { weekNumber, projects } = action.payload;
    state.currentProjects[weekNumber] = projects.map((projectId) => ({ projectId, typeOfWork: '' }));
  },

  setWeekTablesData: (
    state: TimesheetsState,
    action: PayloadAction<{
      weekNumber: string;
      projects: { projectId: string; hours: { displayText: string; value: string; workTimeType: string } }[];
    }>
  ) => {
    const { weekNumber, projects } = action.payload;
    const currentWeeks = state.currentWeeks;
    const tablesData = state.tablesData;

    const [newTablesData, newStatistics] = changeWeekData(
      tablesData,
      currentWeeks,
      weekNumber,
      projects,
      state.currentStatistics
    );

    state.tablesData = newTablesData;
    state.currentStatistics = newStatistics;
  },

  deleteProject: (state: TimesheetsState, action: PayloadAction<{ weekNumber: string; id: string }>) => {
    const { weekNumber, id } = action.payload;
    const newData = deleteProjectData({ ...state.tablesData }, weekNumber, id);

    const newCurrentProjects = { ...state.currentProjects };
    const index = newCurrentProjects[weekNumber].findIndex((el) => el.projectId === id.split('*')[0]);
    newCurrentProjects[weekNumber].splice(index, 1);

    state.tablesData = newData;
    state.currentProjects = newCurrentProjects;
  },
  setTimesheetsTemp: (state: TimesheetsState, action: PayloadAction<any>) => {
    state.temp = {
      ...state.temp,
      ...action.payload,
    };
  },
  fetchTimesheetNotificationsStart: (state: TimesheetsState, action: PayloadAction) => {},
  fetchTimesheetsNotificationsSuccess: (state: TimesheetsState, action: PayloadAction<any>) => {
    state.notifications = {
      ...action.payload,
    };
  },
};

export default reducers;
