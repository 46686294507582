import { Structure } from '@dms-hcms-web/api-interfaces';

const WORKSPACE_STORAGE_KEY = 'workspace';

export const useWorkspace = (): [
  Structure.StructureWorkspaceDto | null,
  (workspace: Structure.StructureWorkspaceDto) => void
] => {
  return [
    JSON.parse(localStorage.getItem(WORKSPACE_STORAGE_KEY) || null) || null,
    (workspace: Structure.StructureWorkspaceDto) =>
      localStorage.setItem(WORKSPACE_STORAGE_KEY, JSON.stringify(workspace)),
  ];
};
