// useSyncLanguage.ts
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useSyncLanguage = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const localStoreLang = localStorage.getItem('i18nextLng');
    if (localStoreLang && i18n.language !== localStoreLang) {
      i18n.changeLanguage(localStoreLang);
    }
  }, [i18n]);
};

export default useSyncLanguage;