import { Employee, StaffListFilter } from '@dms-hcms-web/api-interfaces';
import { PayloadAction } from '@reduxjs/toolkit';

import { EmployeeState } from './employee-slice';

const reducers = {
  fetchListStart: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = '';
    state.isFiltered =
      action.payload.company != null ||
      action.payload.contract != null ||
      action.payload.status != null ||
      action.payload.nameOrId != null ||
      action.payload.hireDate != null ||
      action.payload.role != null;
  },
  fetchListSuccess: (state: EmployeeState, action: PayloadAction<any>) => {
    // StructureListResponse
    state.list = action.payload?.content;
    state.totalEmployees = action.payload?.totalElements;
  },
  fetchListFailure: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = action.payload;
  },
  fetchEmployeeItemStart: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = '';
  },
  fetchEmployeeItemSuccess: (state: EmployeeState, action: PayloadAction<Employee.EmployeeDataItem>) => {
    const employee = action.payload;
    const personalInfo = {
      maritalStatus: employee?.maritalStatus,
      birthPlace: employee?.birthPlace,
      personalContacts: employee?.personalContacts,
      addresses: employee?.addresses,
      emergencyContacts: employee?.emergencyContacts,
      familyMembers: employee?.familyMembers,
      educations: employee?.educations,
      attachments: employee?.attachments,
      residence: employee?.residence,
      nationality: employee?.nationality,
      taxNumber: employee?.taxNumber,
      childrenNumber: employee?.childrenNumber,
    };
    const basicInfo: Employee.EmployeeBasicInfo = {
      hireDate: employee?.hireDate ?? null,
      name: employee?.name,
      nameRu: employee?.nameRu,
      gender: employee?.gender,
      email: employee?.email,
      phoneWork: employee?.phoneWork,
      phoneMobile: employee?.phoneMobile,
      socialLinks: employee?.socialLinks,
      aboutInfo: employee?.aboutInfo,
      birthDate: employee?.birthDate ?? null,
    };
    state.employeeItem = employee;
    state.basicInfo = basicInfo;
    state.personalInfo = personalInfo as Employee.EmployeeDataItem;
  },
  fetchEmployeeItemFailure: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = action.payload;
  },
  fetchEmployeeShortInfoStart: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = '';
  },
  fetchEmployeeShortInfoSuccess: (state: EmployeeState, action: PayloadAction<Employee.EmployeeShortInfo>) => {
    state.shortInfo = action.payload;
  },
  fetchEmployeeShortInfoFailure: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = action.payload;
  },
  fetchContractListSuccess: (state: EmployeeState, action: PayloadAction<any>) => {
    state.contractList = action.payload;
  },
  fetchContractListFailure: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = action.payload;
  },
  fetchStatusListStart: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = '';
  },
  fetchStatusListSuccess: (state: EmployeeState, action: PayloadAction<any>) => {
    state.statusList = action.payload;
  },
  fetchStatusListFailure: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = action.payload;
  },
  fetchMinifiedListSuccess: (state: EmployeeState, action: PayloadAction<any>) => {
    state.listMinified = action.payload?.content;
    state.totalEmployees = action.payload?.total;
  },
  addEmployeeStart: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = '';
  },
  addEmployeeSuccess: (state: EmployeeState, action: PayloadAction<any>) => void {},
  addEmployeeFailure: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = action.payload.message;
  },
  createEmployeeEmailStart: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = '';
  },
  createEmployeeEmailSuccess: (state: EmployeeState, action: PayloadAction<any>) => {
    state.createdEmail = action.payload;
  },
  createEmployeeEmailFailure: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = action.payload.message?.length ? action.payload.message : 'Something went wrong';
  },
  openEmployeeBuilder: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = null;
    state.isEmployeeBuilderOpen = true;
  },
  closeEmployeeBuilder: (state: EmployeeState, action: PayloadAction<any>) => {
    state.isEmployeeBuilderOpen = false;
    state.createdEmail = null;
    state.errorMessage = null;
  },
  updateEmployeeItemStart: (state: EmployeeState, action: PayloadAction<{ id: string; data: Partial<any> }>) => {
    state.errorMessage = '';
  },
  updateEmployeeItemSuccess: (state: EmployeeState, action: PayloadAction<any>) => void {},
  updateEmployeeItemFailure: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = action.payload.message;
  },
  fetchEmployeeBasicInfo: (state: EmployeeState, action: PayloadAction<any>) => {
    state.basicInfo = action.payload;
  },
  fetchEmployeePersonalInfo: (state: EmployeeState, action: PayloadAction<any>) => {
    state.personalInfo = action.payload;
  },
  fetchEmployeeWorkInfoStart: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = '';
  },
  fetchEmployeeWorkInfoSuccess: (state: EmployeeState, action: PayloadAction<any>) => {
    state.workInfo = action.payload;
  },
  fetchEmployeeWorkInfoFailure: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = action.payload?.message;
  },
  fetchEmployeeDocumentsStart: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = '';
  },
  fetchEmployeeDocumentsSuccess: (state: EmployeeState, action: PayloadAction<any>) => {
    state.documents = action.payload;
  },
  fetchEmployeeDocumentsFailure: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = action.payload.message;
  },
  terminateEmployeeItemStart: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = '';
  },
  terminateEmployeeItemSuccess: (state: EmployeeState, action: PayloadAction<any>) => {
    state.terminationStatus = action.payload;
  },
  fetchTerminationStatusStart: (state: EmployeeState, action: PayloadAction) => {
    state.errorMessage = '';
  },
  fetchTerminationStatusSuccess: (state: EmployeeState, action: PayloadAction<any>) => {
    state.terminationStatus = action.payload;
  },
  fetchTerminationStatusFailure: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = action?.payload?.message;
  },
  fetchTimeOffStatusStart: (state: EmployeeState, action: PayloadAction) => {
    state.errorMessage = '';
  },
  fetchTimeOffStatusSuccess: (state: EmployeeState, action: PayloadAction<any>) => {
    state.timeOffStatus = action.payload;
  },
  fetchTimeOffStatusFailure: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = action?.payload?.message;
  },
  updateEmployeeDocumentsStart: (state: EmployeeState, action: PayloadAction<{ id: string; data: any }>) => {
    state.errorMessage = '';
  },
  updateEmployeeDocumentsSuccess: (state: EmployeeState, action: PayloadAction<any>) => {
    state.documents = action.payload;
  },
  updateEmployeeDocumentsFailure: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = action.payload.message;
  },
  updateEmployeePositionsAndSalariesStart: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = '';
  },
  updateEmployeePositionsAndSalariesSuccess: (state: EmployeeState, action: PayloadAction) => {
    state.editList = [];
    state.editListIds = [];
  },
  updateEmployeePositionsAndSalariesFailure: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = action.payload.message;
  },
  setEmployeeEditListIds: (state: EmployeeState, action: PayloadAction<any>) => {
    state.editListIds = action.payload;
  },
  fetchEmployeePositionsAndSalariesStart: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = '';
  },
  fetchEmployeePositionsAndSalariesSuccess: (state: EmployeeState, action: PayloadAction<any>) => {
    state.editList = action.payload;
  },
  fetchEmployeePositionsAndSalariesFailure: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = action.payload.message;
  },
  fetchEmployeeSalaryInfoStart: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = '';
  },
  fetchEmployeeSalaryInfoSuccess: (state: EmployeeState, action: PayloadAction<any>) => {
    state.salaryInfo = action.payload;
  },
  fetchEmployeeSalaryInfoFailure: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = action.payload.message;
  },
  updateEmployeeBasicInfoStart: (state: EmployeeState, action: PayloadAction<{ id: string; data: Partial<any> }>) => {
    state.errorMessage = '';
  },
  updateEmployeeBasicInfoSuccess: (state: EmployeeState, action: PayloadAction<any>) => {
    const data = action.payload;
    const basicInfo: any = {
      hireDate: data?.staffInfo?.hireDate ?? null,
      name: data?.name,
      nameRu: data?.nameRu,
      gender: data?.gender,
      email: data?.email,
      phoneWork: data?.phoneWork,
      phoneMobile: data?.phoneMobile,
      socialLinks: data?.socialLinks,
      aboutInfo: data?.aboutInfo,
      birthDate: data?.birthDate,
    };
    const birthInfo: any = {
      address1: data?.birthPlace?.address1,
      city: data?.birthPlace?.city,
      country: data?.birthPlace?.country,
      state: data?.birthPlace?.state,
    };
    state.employeeItem = data;
    state.basicInfo = basicInfo;
    const personInfo: any = {
      birthPlace: birthInfo,
      maritalStatus: data?.maritalStatus,
      residence: data?.residence,
      childrenNumber: data?.childrenNumber,
      addresses: data?.addresses,
      attachments: data?.attachments,
      educations: data?.educations,
      emergencyContacts: data?.emergencyContacts,
      familyMembers: data?.familyMembers,
      personalContacts: data?.personalContacts,
      nationality: data?.nationality,
      taxNumber: data?.taxNumber,
    };
    state.personalInfo = personInfo;
  },
  updateEmployeeBasicInfoFailure: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = action.payload.message;
  },
  updateEmployeePersonalInfoStart: (state: EmployeeState, action: PayloadAction<{ id: string; data: any }>) => {
    state.errorMessage = '';
  },
  updateEmployeePersonalInfoSuccess: (state: EmployeeState, action: PayloadAction<any>) => {
    const employee = action.payload;
    const personalInfo: any = {
      maritalStatus: employee?.maritalStatus,
      birthPlace: employee?.birthPlace,
      personalContacts: employee?.personalContacts,
      addresses: employee?.addresses,
      emergencyContacts: employee?.emergencyContacts,
      familyMembers: employee?.familyMembers,
      educations: employee?.educations,
      attachments: employee?.attachments,
      residence: employee?.residence,
      nationality: employee?.nationality,
      taxNumber: employee?.taxNumber,
      childrenNumber: employee?.childrenNumber,
    };
    state.employeeItem = employee;
    state.personalInfo = personalInfo;
  },
  updateEmployeePersonalInfoFailure: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = action.payload.message;
  },
  fetchEmployeePersonalAttachmentsStart: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = '';
  },
  fetchEmployeePersonalAttachmentsSuccess: (state: EmployeeState, action: PayloadAction<any>) => {
    state.personalAttachments = action.payload;
  },
  fetchEmployeePersonalAttachmentsFailure: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = action.payload.message;
  },
  createEmployeePersonalAttachmentStart: (
    state: EmployeeState,
    action: PayloadAction<{ id: string; attachmentType: any; fileUploadIds: string[] }>
  ) => {
    state.errorMessage = '';
  },
  createEmployeePersonalAttachmentSuccess: (state: EmployeeState, action: PayloadAction<any>) => {
    state.personalAttachments = action.payload;
  },
  createEmployeePersonalAttachmentFailure: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = action.payload.message;
  },
  deleteEmployeePersonalAttachmentStart: (
    state: EmployeeState,
    action: PayloadAction<{ id: string; attachmentId: string }>
  ) => {
    state.errorMessage = '';
  },
  deleteEmployeePersonalAttachmentSuccess: (state: EmployeeState, action: PayloadAction<any>) => {
    state.personalAttachments = action.payload;
  },
  deleteEmployeePersonalAttachmentFailure: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = action.payload.message;
  },
  fetchNewHcmsList: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = '';
    state.isFiltered =
      action.payload.contract != null ||
      action.payload.status != null ||
      action.payload.nameOrId != null ||
      action.payload.hireDate != null ||
      action.payload.role != null;
  },
  fetchNewHcmsListSuccess: (state: EmployeeState, action: PayloadAction<any>) => {
    state.list = action.payload?.content;
    state.totalEmployees = action.payload?.totalElements;
  },
  fetchStaffListFiltersStart: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = '';
  },
  fetchStaffListFiltersSuccess: (
    state: EmployeeState,
    action: PayloadAction<{ field: any; list: StaffListFilter[] }>
  ) => {
    const { field, list } = action.payload;
    switch (field) {
      case 'status':
        state.statusList = list;
        break;
      case 'contract':
        state.contractList = list;
        break;
      case 'role':
        state.rolesList = list;
        break;
    }
  },
  fetchStaffListFiltersFailure: (state: EmployeeState, action: PayloadAction<any>) => {
    state.errorMessage = action.payload.message;
  },
};

export default reducers;
