/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { toast } from '@dartech/dms-ui';

import { SagaReturnType, all, call, put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  addEmployee,
  createEmployeeEmail,
  createEmployeePersonalAttachment,
  deleteEmployeePersonalAttachment,
  getEmployeeDocuments,
  getEmployeeItem,
  getEmployeePersonalAttachments,
  getEmployeePositionsAndSalaries,
  getEmployeeSalaryInfo,
  getEmployeeWorkInfo,
  getShortInfo,
  getStatusList,
  getTerminationStatus,
  getTimeOffStatus,
  terminateEmployeeItem,
  updateEmployeeBasicInfo,
  updateEmployeeDocuments,
  updateEmployeeItem,
  updateEmployeePersonalInfo,
  updateEmployeePositionsAndSalaries,
} from '@services/employee';
import { getStaffListFilters } from '@services/structure';

import i18n from '@i18n';

import { setFetchingFailure, setFetchingStart, setFetchingSuccess } from '../loading/loading-slice';
import {
  addEmployeeFailure,
  addEmployeeStart,
  addEmployeeSuccess,
  closeEmployeeBuilder,
  createEmployeeEmailFailure,
  createEmployeeEmailStart,
  createEmployeeEmailSuccess,
  createEmployeePersonalAttachmentFailure,
  createEmployeePersonalAttachmentStart,
  createEmployeePersonalAttachmentSuccess,
  deleteEmployeePersonalAttachmentFailure,
  deleteEmployeePersonalAttachmentStart,
  deleteEmployeePersonalAttachmentSuccess,
  fetchEmployeeDocumentsFailure,
  fetchEmployeeDocumentsStart,
  fetchEmployeeDocumentsSuccess,
  fetchEmployeeItemFailure,
  fetchEmployeeItemStart,
  fetchEmployeeItemSuccess,
  fetchEmployeePersonalAttachmentsStart,
  fetchEmployeePersonalAttachmentsSuccess,
  fetchEmployeePositionsAndSalariesStart,
  fetchEmployeePositionsAndSalariesSuccess,
  fetchEmployeeSalaryInfoFailure,
  fetchEmployeeSalaryInfoStart,
  fetchEmployeeSalaryInfoSuccess,
  fetchEmployeeShortInfoFailure,
  fetchEmployeeShortInfoStart,
  fetchEmployeeShortInfoSuccess,
  fetchEmployeeWorkInfoFailure,
  fetchEmployeeWorkInfoStart,
  fetchEmployeeWorkInfoSuccess,
  fetchStaffListFiltersFailure,
  fetchStaffListFiltersStart,
  fetchStaffListFiltersSuccess,
  fetchStatusListFailure,
  fetchStatusListStart,
  fetchStatusListSuccess,
  fetchTerminationStatusFailure,
  fetchTerminationStatusStart,
  fetchTerminationStatusSuccess,
  fetchTimeOffStatusFailure,
  fetchTimeOffStatusStart,
  fetchTimeOffStatusSuccess,
  terminateEmployeeItemStart,
  terminateEmployeeItemSuccess,
  updateEmployeeBasicInfoFailure,
  updateEmployeeBasicInfoStart,
  updateEmployeeBasicInfoSuccess,
  updateEmployeeDocumentsFailure,
  updateEmployeeDocumentsStart,
  updateEmployeeDocumentsSuccess,
  updateEmployeeItemFailure,
  updateEmployeeItemStart,
  updateEmployeeItemSuccess,
  updateEmployeePersonalInfoFailure,
  updateEmployeePersonalInfoStart,
  updateEmployeePersonalInfoSuccess,
  updateEmployeePositionsAndSalariesStart,
  updateEmployeePositionsAndSalariesSuccess,
} from './employee-slice';

export function* fetchEmployeeItemAsync(action: PayloadAction<string>) {
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof getEmployeeItem> = yield getEmployeeItem({ employeeId: action.payload });
    const data = res?.data;
    yield put(fetchEmployeeItemSuccess(data));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(fetchEmployeeItemFailure(error.message));
    yield put(setFetchingFailure(action.type));
  }
}

export function* fetchEmployeeShortInfoAsync(action: PayloadAction<string>) {
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof getShortInfo> = yield getShortInfo({ employeeId: action.payload });
    const data = res?.data;
    yield put(fetchEmployeeShortInfoSuccess(data));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(fetchEmployeeShortInfoFailure(error.message));
    yield put(setFetchingFailure(action.type));
  }
}

export function* fetchStatusListAsync(action: PayloadAction<string>) {
  yield put(setFetchingStart(action.type));
  try {
    const res: any = yield getStatusList(action.payload);
    const statusList = res?.data?.entities;
    yield put(fetchStatusListSuccess(statusList));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(fetchStatusListFailure(error.message));
    yield put(setFetchingFailure(action.type));
  }
}

export function* fetchStaffListFiltersAsync(action: PayloadAction<any>) {
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof getStaffListFilters> = yield getStaffListFilters(action.payload);
    const list = res?.data;
    yield put(fetchStaffListFiltersSuccess({ field: action.payload, list }));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(fetchStaffListFiltersFailure(error.message));
    yield put(setFetchingFailure(action.type));
  }
}

export function* addEmployeeAsync(action: PayloadAction<any>) {
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof addEmployee> = yield addEmployee(action.payload);
    const employee = res.data;
    yield put(addEmployeeSuccess(employee));
    yield put(setFetchingSuccess(action.type));
    yield put(closeEmployeeBuilder(action.type));
    toast.success(
      i18n.t('invite_new.employee_invite', {
        name: `${employee?.name?.first} ${employee?.name?.last}`,
        mail: employee?.personalContacts?.email,
      }),
      { title: ' ' }
    );
  } catch (error) {
    yield put(addEmployeeFailure(error));
    yield put(setFetchingFailure(action.type));
    toast.error(
      error?.message?.length
        ? error?.message?.includes('already')
          ? i18n.t('invite_new.user_in_workspace')
          : error?.message
        : 'Something went wrong',
      { title: ' ' }
    );
  }
}

export function* createEmployeeEmailAsync(action: PayloadAction<any>) {
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof createEmployeeEmail> = yield createEmployeeEmail(action.payload);
    yield put(createEmployeeEmailSuccess(res.data));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(createEmployeeEmailFailure(error));
    yield put(setFetchingFailure(action.type));
  }
}

export function* updateEmployeeItemAsync(action: PayloadAction<{ id: string; data: any }>) {
  const { id, data } = action.payload;
  yield put(setFetchingStart(action.type));
  try {
    const res: any = yield updateEmployeeItem({ employeeId: id, body: data });
    yield put(updateEmployeeItemSuccess(action.type));
    toast.success('Your details have been updated.', { title: ' ' });
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(updateEmployeeItemFailure(error));
    yield put(setFetchingFailure(action.type));
  }
}

export function* fetchTerminationStatusAsync(action: PayloadAction) {
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof getTerminationStatus> = yield getTerminationStatus();
    yield put(fetchTerminationStatusSuccess(res?.data));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(fetchTerminationStatusFailure(error.message));
    yield put(setFetchingFailure(action.type));
  }
}

export function* terminateEmployeeItemAsync(action: PayloadAction<any>) {
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof terminateEmployeeItem> = yield terminateEmployeeItem(action.payload);
    yield put(terminateEmployeeItemSuccess(res?.data));
    yield put(setFetchingSuccess(action.type));
    toast.success(`Success. Dismissal is created`, { title: ' ' });
  } catch (error) {
    yield put(setFetchingFailure(action.type));
    toast.error(error.message);
  }
}

export function* fetchTimeOffStatusAsync(action: PayloadAction) {
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof getTimeOffStatus> = yield getTimeOffStatus();
    yield put(fetchTimeOffStatusSuccess(res?.data));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(fetchTimeOffStatusFailure(error.message));
    yield put(setFetchingFailure(action.type));
  }
}

export function* fetchEmployeeWorkInfoAsync(action: PayloadAction<string>) {
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof getEmployeeWorkInfo> = yield getEmployeeWorkInfo({ employeeId: action.payload });
    const data = res?.data;
    yield put(fetchEmployeeWorkInfoSuccess(data));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(fetchEmployeeWorkInfoFailure(error?.message));
    yield put(setFetchingFailure(action.type));
  }
}

export function* fetchEmployeeDocumentsAsync(action: PayloadAction<string>) {
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof getEmployeeDocuments> = yield getEmployeeDocuments(action.payload);
    const documents = res?.data;
    yield put(fetchEmployeeDocumentsSuccess(documents));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(fetchEmployeeDocumentsFailure(error.message));
    yield put(setFetchingFailure(action.type));
  }
}

export function* updateEmployeeDocumentsAsync(action: PayloadAction<{ id: string; data: any }>) {
  const { id, data } = action.payload;
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof updateEmployeeDocuments> = yield updateEmployeeDocuments({
      employeeId: id,
      body: data,
    });
    yield put(updateEmployeeDocumentsSuccess(res?.data));
    toast.success('Your documents have been updated.', { title: ' ' });
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(updateEmployeeDocumentsFailure(error));
    yield put(setFetchingFailure(action.type));
  }
}

export function* updateEmployeePositionsAndSalariesAsync(action: PayloadAction<any>) {
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof updateEmployeePositionsAndSalaries> = yield updateEmployeePositionsAndSalaries(
      action.payload
    );
    yield put(updateEmployeePositionsAndSalariesSuccess());
    toast.success('Changes applied successfully.', { title: ' ' });
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    toast.error(error.message);
    // yield put(updateEmployeePositionsAndSalariesFailure(error));
    yield put(setFetchingFailure(action.type));
  }
}

export function* fetchEmployeeSalaryInfoAsync(action: PayloadAction<string>) {
  yield put(setFetchingStart(action.type));
  try {
    const res: any = yield getEmployeeSalaryInfo(action.payload);
    const data = res?.data?.entities;
    yield put(fetchEmployeeSalaryInfoSuccess(data));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(fetchEmployeeSalaryInfoFailure(error.message));
    yield put(setFetchingFailure(action.type));
  }
}

export function* fetchEmployeePositionsAndSalariesAsync(action: PayloadAction<any>) {
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof getEmployeePositionsAndSalaries> = yield getEmployeePositionsAndSalaries(
      action.payload
    );
    yield put(fetchEmployeePositionsAndSalariesSuccess(res?.data));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    toast.error(error.message);
    // yield put(fetchEmployeePositionsAndSalariesFailure(error));
    yield put(setFetchingFailure(action.type));
  }
}

export function* updateEmployeeBasicInfoAsync(action: PayloadAction<{ id: string; data: any }>) {
  const { id, data } = action.payload;
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof updateEmployeeBasicInfo> = yield updateEmployeeItem({
      employeeId: id,
      body: data,
    });
    yield put(updateEmployeeBasicInfoSuccess(res?.data));
    toast.success(i18n.t('success_messages.success_updated'), { title: ' ' });
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(updateEmployeeBasicInfoFailure(error));
    yield put(setFetchingFailure(action.type));
  }
}

export function* updateEmployeePersonalInfoAsync(action: PayloadAction<{ id: string; data: any }>) {
  const { id, data } = action.payload;
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof updateEmployeePersonalInfo> = yield updateEmployeePersonalInfo({
      employeeId: id,
      body: data,
    });
    yield put(updateEmployeePersonalInfoSuccess(res?.data));
    toast.success(i18n.t('success_messages.success_updated'), { title: ' ' });
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(updateEmployeePersonalInfoFailure(error));
    yield put(setFetchingFailure(action.type));
  }
}

export function* fetchEmployeePersonalAttachmentsAsync(action: PayloadAction<string>) {
  yield put(setFetchingStart(action.type));
  try {
    const res: any = yield getEmployeePersonalAttachments(action.payload);
    yield put(fetchEmployeePersonalAttachmentsSuccess(res?.data?.attachments));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(setFetchingFailure(action.type));
  }
}

export function* createEmployeePersonalAttachmentAsync(
  action: PayloadAction<{ id: string; attachmentType: any; fileUploadIds: string[] }>
) {
  yield put(setFetchingStart(action.type));
  try {
    const { id, attachmentType, fileUploadIds } = action.payload;
    let res: any;
    for (let i = 0; i < fileUploadIds?.length; i++) {
      const data: any = {
        fileUploadId: fileUploadIds[i],
      };
      res = yield createEmployeePersonalAttachment({ employeeId: id, attachmentType, data });
    }
    yield put(createEmployeePersonalAttachmentSuccess(res?.data?.attachments));
    yield put(setFetchingSuccess(action.type));
    toast.success(`${fileUploadIds?.length > 1 ? 'Attachments' : 'Attachment'} added successfully!`, { title: ' ' });
  } catch (error) {
    toast.error(error.message);
    yield put(createEmployeePersonalAttachmentFailure(error));
    yield put(setFetchingFailure(action.type));
  }
}

export function* deleteEmployeePersonalAttachmentAsync(action: PayloadAction<{ id: string; attachmentId: string }>) {
  yield put(setFetchingStart(action.type));
  try {
    const { id, attachmentId } = action.payload;
    const res: any = yield deleteEmployeePersonalAttachment({
      employeeId: id,
      attachmentId,
    });
    yield put(deleteEmployeePersonalAttachmentSuccess(res?.data?.attachments));
    yield put(setFetchingSuccess(action.type));
    toast.success('Attachment deleted successfully!', { title: ' ' });
  } catch (error) {
    toast.error(error.message);
    yield put(deleteEmployeePersonalAttachmentFailure(error));
    yield put(setFetchingFailure(action.type));
  }
}

export function* onFetchEmployeeItemStart() {
  yield takeLatest(fetchEmployeeItemStart.type, fetchEmployeeItemAsync);
}

export function* onFetchEmployeeShortInfoStart() {
  yield takeLatest(fetchEmployeeShortInfoStart.type, fetchEmployeeShortInfoAsync);
}

export function* onFetchStatusListStart() {
  yield takeLatest(fetchStatusListStart.type, fetchStatusListAsync);
}

export function* onAddEmployeeStart() {
  yield takeLatest(addEmployeeStart.type, addEmployeeAsync);
}

export function* onCreateEmployeeEmailStart() {
  yield takeLatest(createEmployeeEmailStart.type, createEmployeeEmailAsync);
}

export function* onUpdateEmployeeItemStart() {
  yield takeLatest(updateEmployeeItemStart.type, updateEmployeeItemAsync);
}

export function* onFetchTerminationStatusStart() {
  yield takeLatest(fetchTerminationStatusStart.type, fetchTerminationStatusAsync);
}

export function* onTerminateEmployeeItemStart() {
  yield takeLatest(terminateEmployeeItemStart.type, terminateEmployeeItemAsync);
}

export function* onFetchTimeOffStatusStart() {
  yield takeLatest(fetchTimeOffStatusStart.type, fetchTimeOffStatusAsync);
}

export function* onFetchEmployeeWorkInfoStart() {
  yield takeLatest(fetchEmployeeWorkInfoStart.type, fetchEmployeeWorkInfoAsync);
}

export function* onFetchEmployeeDocumentsStart() {
  yield takeLatest(fetchEmployeeDocumentsStart.type, fetchEmployeeDocumentsAsync);
}

export function* onUpdateEmployeeDocumentsAsync() {
  yield takeLatest(updateEmployeeDocumentsStart.type, updateEmployeeDocumentsAsync);
}

export function* onUpdateEmployeePositionsAndSalariesAsync() {
  yield takeLatest(updateEmployeePositionsAndSalariesStart.type, updateEmployeePositionsAndSalariesAsync);
}

export function* onFetchEmployeeSalaryInfoStart() {
  yield takeLatest(fetchEmployeeSalaryInfoStart.type, fetchEmployeeSalaryInfoAsync);
}

export function* onFetchEmployeePositionsAndSalariesAsync() {
  yield takeLatest(fetchEmployeePositionsAndSalariesStart.type, fetchEmployeePositionsAndSalariesAsync);
}

export function* onUpdateEmployeeBasicInfoStart() {
  yield takeLatest(updateEmployeeBasicInfoStart.type, updateEmployeeBasicInfoAsync);
}

export function* onUpdateEmployeePersonalInfoStart() {
  yield takeLatest(updateEmployeePersonalInfoStart.type, updateEmployeePersonalInfoAsync);
}

export function* onFetchEmployeePersonalAttachmentsStart() {
  yield takeLatest(fetchEmployeePersonalAttachmentsStart.type, fetchEmployeePersonalAttachmentsAsync);
}

export function* onCreateEmployeePersonalAttachmentStart() {
  yield takeLatest(createEmployeePersonalAttachmentStart.type, createEmployeePersonalAttachmentAsync);
}

export function* onDeleteEmployeePersonalAttachmentStart() {
  yield takeLatest(deleteEmployeePersonalAttachmentStart.type, deleteEmployeePersonalAttachmentAsync);
}

export function* onFetchStaffListFiltersStart() {
  yield takeLatest(fetchStaffListFiltersStart.type, fetchStaffListFiltersAsync);
}

export function* employeeSagas() {
  yield all([
    call(onFetchStatusListStart),
    call(onFetchEmployeeShortInfoStart),
    call(onFetchEmployeeItemStart),
    call(onAddEmployeeStart),
    call(onCreateEmployeeEmailStart),
    call(onUpdateEmployeeItemStart),
    call(onFetchTerminationStatusStart),
    call(onTerminateEmployeeItemStart),
    call(onFetchTimeOffStatusStart),
    call(onFetchEmployeeWorkInfoStart),
    call(onFetchEmployeeDocumentsStart),
    call(onUpdateEmployeeDocumentsAsync),
    call(onUpdateEmployeePositionsAndSalariesAsync),
    call(onFetchEmployeeSalaryInfoStart),
    call(onFetchEmployeePositionsAndSalariesAsync),
    call(onUpdateEmployeeBasicInfoStart),
    call(onUpdateEmployeePersonalInfoStart),
    call(onFetchEmployeePersonalAttachmentsStart),
    call(onCreateEmployeePersonalAttachmentStart),
    call(onDeleteEmployeePersonalAttachmentStart),
    call(onFetchStaffListFiltersStart),
  ]);
}
