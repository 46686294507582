// import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import { environment } from '../../environments/environment';
import reducer from './root-reducer';
import rootSaga from './root-saga';

const sagaMiddleware = createSagaMiddleware();
const middleware = [...getDefaultMiddleware({ thunk: false }), sagaMiddleware];
const dev = JSON.parse(localStorage.getItem('profile'));

if (!environment.production && dev && dev.id !== 'c460892d-b50e-47fc-bdb6-709a120c503e') {
  // middleware.push(logger);
}

export const store = configureStore({
  reducer,
  middleware,
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
