import { Spinner as DmsUiSpinner, SpinnerProps } from '@dartech/dms-ui';
import React, { FC } from 'react';

import { useSidebarState } from '@hooks/useSidebarState';

export const Spinner: FC<SpinnerProps> = ({ ...props }) => {
  const isNavMini = useSidebarState();

  return <DmsUiSpinner {...props} isNavMinimized={isNavMini} />;
};
