import { environment } from '@environment';

import {
  CreateOrgStructureDTO,
  Employee,
  OrgStructure,
  OrgStructureExport,
  OrgStructureListResponse,
  OrgStructureResponse,
  Pagination,
  PlainWorkgroup,
  SetStatusDTO,
  SnapshotDetails,
  SnapshotListResponse,
  TreeStructureNode,
  UpdateOrgStructureDTO,
} from '@dms-hcms-web/api-interfaces';
import { getCompany } from '@shared/company';

import httpClient from './http';

export const create = (data: CreateOrgStructureDTO) => {
  const company = getCompany();
  return httpClient.post<OrgStructure>(`${environment.baseApiUrl}/orgstructure`, data, {
    params: {
      companyId: company.id,
    },
  });
};

export const getList = () => {
  const company = getCompany();
  return httpClient.get<OrgStructureListResponse>(`${environment.baseApiUrl}/orgstructure`, {
    params: {
      companyId: company.id,
    },
  });
};

export const get = (id: string) => {
  const company = getCompany();
  return httpClient.get<OrgStructureResponse>(`${environment.baseApiUrl}/orgstructure/${id}`, {
    params: {
      companyId: company.id,
    },
  });
};

export const update = (id: string, data: Partial<UpdateOrgStructureDTO>) => {
  return httpClient.put(`${environment.baseApiUrl}/orgstructure/${id}`, data);
};

export const deleteItem = (id: string) => {
  return httpClient.delete(`${environment.baseApiUrl}/orgstructure/${id}`);
};

export const setStatus = (id: string, data: Partial<SetStatusDTO>) => {
  const company = getCompany();
  return httpClient.put(`${environment.baseApiUrl}/orgstructure/${id}/status`, data, {
    params: {
      companyId: company.id,
    },
  });
};

export const duplicate = (id: string) => {
  const company = getCompany();
  return httpClient.put<OrgStructure>(`${environment.baseApiUrl}/orgstructure/${id}/duplicate`, null, {
    params: {
      companyId: company.id,
    },
  });
};

export const addWorkgroup = (orgStructureId: string, data: PlainWorkgroup) => {
  return httpClient.post<OrgStructure>(`${environment.baseApiUrl}/orgstructure/${orgStructureId}/work-groups`, data);
};

export const updateWorkgroup = (id: string, workGroupId: string, data: Partial<PlainWorkgroup>) => {
  return httpClient.put(`${environment.baseApiUrl}/orgstructure/${id}/work-groups/${workGroupId}`, data);
};

export const deleteWorkgroup = (id: string, workgroupId: string) => {
  return httpClient.delete(`${environment.baseApiUrl}/orgstructure/${id}/work-groups/${workgroupId}`);
};

export const getSnapshotList = () => {
  const company = getCompany();
  return httpClient.get<SnapshotListResponse>(`${environment.baseApiUrl}/orgstructure/snapshot/list`, {
    params: {
      companyId: company.id,
    },
  });
};

export const getSnapshotDetails = (snapshotId: string) => {
  const company = getCompany();
  return httpClient.get<SnapshotDetails>(`${environment.baseApiUrl}/orgstructure/snapshot/details/${snapshotId}`, {
    params: {
      companyId: company.id,
    },
  });
};

export const getTreeStructure = () => {
  const company = getCompany();
  return httpClient.get<TreeStructureNode>(`${environment.baseApiUrl}/orgstructure/tree?companyId=${company.id}`);
};

export const getExternalTreeStructure = (companyId: string) => {
  return httpClient.get<TreeStructureNode>(`${environment.baseApiUrl}/orgstructure/external/tree`, {
    params: {
      companyId,
    },
  });
};

export const exportOrgStructure = (id: string) => {
  const company = getCompany();
  return httpClient.post<OrgStructureExport>(
    `${environment.baseApiUrl}/orgstructure/${id}/export?companyId=${company.id}`
  );
};

export const getOrgStructureExportLink = (id: string) => {
  const company = getCompany();
  return httpClient.get<OrgStructureExport>(
    `${environment.baseApiUrl}/orgstructure/export/${id}?companyId=${company.id}`
  );
};

export const getStructureList = (
  pageable?: Pagination,
  status?: string,
  contract?: string,
  nameOrId?: string,
  role?: string,
  companyId?: string
) => {
  const company = getCompany();
  return httpClient.get<any>(`${environment.baseApiUrl}/structure?companyId=${companyId ?? company.id}`, {
    params: { ...pageable, nameOrId, status, contract, role },
  });
};

export const getStructureCompanyEmployeeList = (status?: string, role?: string, companyId?: string) => {
  const company = getCompany();
  return httpClient.get<Employee.EmployeeStructureItemDto[]>(
    `${environment.baseApiUrl}/structure/company/employees?companyId=${companyId ?? company.id}`,
    {
      params: { status, role },
    }
  );
};

export const getNewHcmsStructureList = (
  pageable: Pagination,
  status?: string,
  contract?: string,
  nameOrId?: string,
  hireDate?: string
) => {
  const company = getCompany();
  return httpClient.get<any>(`${environment.baseApiUrl}/structure/new-hcms`, {
    params: {
      companyId: company.id,
      ...pageable,
      nameOrId,
      status,
      hireDate,
      contract,
    },
  });
};

export const getStruttureShortInfo = () => {
  const company = getCompany();
  return httpClient.get<any>(`${environment.baseApiUrl}/structure/shortInfo`, {
    params: {
      companyId: company.id,
      status: 'ACTIVE',
    },
  });
};

export const updateUser = (id: string, body: any) => {
  return httpClient.put(`${environment.baseApiUrl}/structure/update/users/${id}`, body).then((res) => res.data);
};
