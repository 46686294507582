import { MenuProps } from '@mui/material';

type ChipValue = { title: string; icon?: string };
export const HCMS_PREFIX = 'HCMS';

export const WORKSPACE_TOKEN_STORAGE_KEY = 'workspace_token';
export const WORKSPACE_STORAGE_KEY = 'workspace';

export const STATUS_MAP = {
  VERIFYING_BY_SUPERVISOR: {
    text: 'Under Manager Verification',
    localizationKey: 'timesheet_status.under_manager_verification',
    color: '#FFA530',
  },
  VERIFYING_BY_HR: {
    text: 'Under HR Verification',
    localizationKey: 'timesheet_status.under_hr_verification',
    color: '#FFA530',
  },
  DRAFT: {
    text: 'Draft',
    localizationKey: 'timesheet_status.draft',
    color: '#404D60',
  },
  REWORK: {
    text: 'Rework',
    localizationKey: 'timesheet_status.rework',
    color: '#277DBB',
  },
  ACTIVE: {
    text: 'Active',
    color: '#2DB77B',
    localizationKey: 'user_status.active',
  },
  INACTIVE: {
    text: 'Inactive',
    color: '#99A5B1',
  },
  MATERNITY_LEAVE: {
    text: 'Maternity leave',
    color: '#976AD1',
  },
  LONG_UNPAID_LEAVE: {
    text: 'Long unpaid leave',
    color: '#3781CB',
  },
  RESIGNED: {
    text: 'Resigned',
    color: '#99A5B1',
  },
  TERMINATED: {
    text: 'Terminated',
    color: '#99A5B1',
    localizationKey: 'user_status.terminated',
  },
  PUBLISHED: {
    text: 'Published',
    localizationKey: 'timesheet_status.published',
    color: '#50CC6B',
  },
  UNDER_VERIFICATION: {
    text: 'Under verification',
    color: '#FFA530',
  },
  UNDEFINED: {
    text: 'Undefined',
    color: '#5f5f5f',
  },
  OPEN: {
    text: 'Open',
    color: '#2DB77B',
    localizationKey: 'vacancy_status.open',
  },
  CANCELED: {
    text: 'Cancelled',
    color: '#D6331F',
    localizationKey: 'vacancy_status.cancelled',
  },
  ON_HOLD: {
    text: 'On hold',
    color: '#6D6E85',
    localizationKey: 'vacancy_status.on_hold',
  },
};

export const MARITAL_STATUSES = ['Single', 'Married', 'Common Law'];

export const DEGREES = ["Bachelor's Degree", "Master's Degree", "PhD's Degree"];

export const EMERGENCY_RELATIONSHIPS = [
  'Colleague',
  'Daugther',
  'Father',
  'Friend',
  'Husband',
  'Mother',
  'Brother',
  'Sister',
  'Son',
  'Wife',
  'Other',
];

export const FAMILY_RELATIONSHIPS = ['Daugther', 'Father', 'Husband', 'Mother', 'Brother', 'Sister', 'Son', 'Wife'];

export const HOBBIES = (t: any): ChipValue[] => [
  {
    title: t('editabout.hobby1'),
    // icon: 'M',
  },
  {
    title: t('editabout.hobby2'),
    // icon: 'F',
  },
  {
    title: t('editabout.hobby3'),
    // icon: 'R',
  },
  {
    title: t('editabout.hobby4'),
    // icon: 'T',
  },
  {
    title: t('editabout.hobby5'),
    // icon: 'F',
  },
  {
    title: t('editabout.hobby6'),
    // icon: 'F',
  },
  {
    title: t('editabout.hobby7'),
    // icon: 'R',
  },
  {
    title: t('editabout.hobby8'),
    // icon: 'D',
  },
  {
    title: t('editabout.hobby9'),
    // icon: 'C',
  },
];

export const colors = [
  '#DE844A',
  '#DEC64A',
  '#B8DE4A',
  '#5AA573',
  '#5FD7D7',
  '#33AFE5',
  '#507DD6',
  '#4455EE',
  '#7B44EE',
  '#CC44EE',
  '#F451ED',
  '#EA2D7D',
];

export const DOCUMENTS = ['ID', 'Passport', 'Residence permit'];

export const AUTHORITIES = ['Ministry of Internal Affairs', 'Ministry of Justice'];

export const SelectMenuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  // getContentAnchorEl: null,
};

export const REQUIRED_ERROR_MESSAGES = (t: any): { [key: string]: string } => {
  return {
    REQUIRED_FIELD: t('general.requiredField'),
    LATIN_MESSAGE: t('general.required.latin'),
    CYRILLIC_MESSAGE: t('general.required.cyrillic'),
    NUMBER_MESSAGE: t('general.required.correctNumbers'),
    LATIN_AND_NUMBER_MESSAGE: t('general.required.latinNumbers'),
  };
};
