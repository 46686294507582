import { Timesheet } from '@dms-hcms-web/api-interfaces';

export const widgetTranslationKeys = {
  TOTAL: 'timesheets.hours_widget',
  WORK: 'timesheets.work_hours_widget',
  DAY_OFF: 'timesheets.day_off_widget',
  SICK_LEAVE: 'timesheets.sick_leave_widget',
  PAID_VACATION_LEAVE: 'timesheets.vac_paid_leave_widget',
  UNPAID_VACATION_LEAVE: 'timesheets.vac_unpaid_leave_widget',
  LONG_UNPAID_LEAVE: 'timesheets.long_unpaid_leave_widget',
  // ABSENCE_NON_REASONABLE: 'timesheets.non_reason_leave_widget',
  BUSINESS_TRIP: 'timesheets.business_trip_widget',
  MATERNITY_LEAVE: 'timesheets.maternity_leave_widget',
  CHILD_CARE_LEAVE: 'timesheets.child_care_leave_widget',
};

export const widgetValues = {
  TOTAL: 'Total',
  WORK: 'Work',
  DAY_OFF: 'Day Off',
  SICK_LEAVE: 'Sick Leave',
  PAID_VACATION_LEAVE: 'Vacation Paid Leave',
  UNPAID_VACATION_LEAVE: 'Vacation Unpaid Leave',
  LONG_UNPAID_LEAVE: 'Long Unpaid Leave',
  // ABSENCE_NON_REASONABLE: 'Non-reasonable Absence',
  BUSINESS_TRIP: 'Business Trip',
  MATERNITY_LEAVE: 'Maternity Leave',
  CHILD_CARE_LEAVE: 'Child Care Leave',
  TERMINATED: 'Terminated',
};

const nextStatusOption = {
  UNDEFINED: 'DRAFT',
  DRAFT: 'VERIFYING_BY_SUPERVISOR',
  REWORK: 'VERIFYING_BY_SUPERVISOR',
  VERIFYING_BY_SUPERVISOR: 'VERIFYING_BY_HR',
  VERIFYING_BY_HR: 'PUBLISHED',
} as const;
export const getNextStatus = (status: Timesheet.TimesheetStatus): Timesheet.TimesheetStatus => {
  return nextStatusOption[status] || 'UNDEFINED';
};

export const fetchFormData = (
  currentDays: Timesheet.TimesheetDay[],
  values: { [key: string]: Record<string, { value: Timesheet.WorkTimeType; label: string }> }
): Timesheet.TimesheetDay[] => {
  const data = [];
  const days = currentDays;

  days.forEach((day) => {
    const {
      date,
      // weekNumber
    } = day;
    const workedOn = [];
    const projects = values[date.toString()];
    let workTimeType: Timesheet.WorkTimeType = 'WORK';

    if (projects) {
      workTimeType = Object.values(projects)[0].value || 'WORK';
      Object.keys(projects).forEach((key) => {
        const projectId = key.split('*')[0];
        const value = projects[key];
        const projectData = {
          projectId,
          hours: +value || 0.0,
          // typeOfWork: values.typeOfWork[weekNumber][key],
          typeOfWork: '',
        };
        workedOn.push(projectData);
      });
    }

    data.push({ date, workTimeType, workedOn });
  });

  return data;
};
