import { environment } from '@environment';

import { OktaAuth } from '@okta/okta-auth-js';

const ISSUER = `https://${environment.oktaDomain}`;
const CLIENT_ID = environment.oktaClientId;
const CALLBACK_PATH = '/callback';
const REDIRECT_URI = `${window.location.origin}${CALLBACK_PATH}`;

const oktaAuth = new OktaAuth({
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: REDIRECT_URI,
});

export default oktaAuth;
