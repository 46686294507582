import { useEffect, useState } from 'react';

import { isNavMini$ } from '@dar-dms/topbar';

export const useSidebarState = () => {
  const [isNavMini, setIsNavMini] = useState(false);

  useEffect(() => {
    if (isNavMini$) {
      const subscription = isNavMini$.subscribe(setIsNavMini);

      return () => {
        subscription.unsubscribe();
      };
    }
  }, []);
  return isNavMini;
};
