import styled from 'styled-components';

const App = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  transition: width 0.5s ease-in-out;
`;

const Main = styled('div')(
  () =>
    ({
      flex: '1 1 20%',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      '.Main-breadcrumb-back': {
        marginRight: 15,
        '& > button': {
          height: 25,
          width: 25,
        },
      },
    } as const)
);

export const Styled = {
  App,
  Main,
};
