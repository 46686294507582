import { OrgStructure, Snapshot, SnapshotDetails, TreeStructureNode, Workgroup } from '@dms-hcms-web/api-interfaces';
import { createSlice } from '@reduxjs/toolkit';

import reducers from './org-structure-reducer';

export interface OrgStructureState {
  list: OrgStructure[];
  item: OrgStructure;
  workGroups: Workgroup[];
  snapshots: Snapshot[];
  snapshotDetails: SnapshotDetails;
  isClusterBuilderOpen: boolean;
  selectedClusterIndex: number;
  initialWorkgroups: Workgroup[];
  initialClusterProjects: Workgroup[];
  isProjectBuilderOpen: boolean;
  newOrgStructure: OrgStructure;
  selectedProjectIndex: number;
  dragSourceParentIndex: number;
  dragDestinationParentIndex: number;
  errorMessage: string;
  isFetching: boolean;
  tree: TreeStructureNode;
  employeers: any[];
}

export const initialState: OrgStructureState = {
  list: [],
  item: null,
  workGroups: [],
  snapshots: [],
  snapshotDetails: null,
  isClusterBuilderOpen: false,
  selectedClusterIndex: null,
  initialWorkgroups: [],
  initialClusterProjects: null,
  isProjectBuilderOpen: false,
  newOrgStructure: null,
  selectedProjectIndex: null,
  dragSourceParentIndex: null,
  dragDestinationParentIndex: null,
  errorMessage: null,
  isFetching: false,
  tree: null,
  employeers: [],
};

export const orgStructureSlice = createSlice({
  name: 'orgStructure',
  initialState,
  reducers,
});

export const {
  createOrgStructureStart,
  createOrgStructureSuccess,
  createOrgStructureFailure,
  fetchItemStart,
  fetchItemSuccess,
  fetchItemFailure,
  fetchListStart,
  fetchListSuccess,
  fetchListFailure,
  fetchSnapshotListStart,
  fetchSnapshotListSuccess,
  fetchSnapshotListFailure,
  fetchSnapshotDetailsStart,
  fetchSnapshotDetailsSuccess,
  fetchSnapshotDetailsFailure,
  updateOrgStructureStart,
  updateOrgStructureSuccess,
  updateOrgStructureFailure,
  deleteOrgStructureStart,
  deleteOrgStructureSuccess,
  deleteOrgStructureFailure,
  setOrgStructureStatusStart,
  setOrgStructureStatusSuccess,
  setOrgStructureStatusFailure,
  duplicateOrgStructureStart,
  duplicateOrgStructureSuccess,
  duplicateOrgStructureFailure,
  addWorkgroupStart,
  addWorkgroupSuccess,
  addWorkgroupFailure,
  updateWorkgroupStart,
  updateWorkgroupSuccess,
  updateWorkgroupFailure,
  deleteWorkgroupStart,
  deleteWorkgroupSuccess,
  deleteWorkgroupFailure,
  fetchTreeStart,
  fetchTreeSuccess,
  fetchTreeFailure,

  openClusterBuilder,
  closeClusterBuilder,
  openProjectBuilder,
  closeProjectBuilder,
  changeClusterEntityRelation,
  changeProjectEntityRelation,
  changeClusterColor,
  markWorkgroupCaptain,
  saveProject,
  deleteProject,
  addClusterEmployee,
  removeClusterEmployee,
  addProjectEmployee,
  removeProjectEmployee,
  addClusterVacancy,
  removeClusterVacancy,
  addProjectVacancy,
  removeProjectVacancy,
  moveClusterEmployee,
  moveProjectEmployee,
  moveProject,
  getShortInfoStart,
  getShortInfoSuccess,
} = orgStructureSlice.actions;

export default orgStructureSlice.reducer;
