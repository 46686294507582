import { format } from 'date-fns';
import * as _ from 'lodash';

import { Timesheet } from '@dms-hcms-web/api-interfaces';

export type TimesheetWeeksType = Record<string, Timesheet.TimesheetDay[]>;
export const retrieveWeeks = (days: Timesheet.TimesheetDay[]) => {
  return days.reduce<TimesheetWeeksType>((acc, day) => {
    const actualKey = day.weekNumber;
    if (!acc[actualKey]) acc[actualKey] = [];
    acc[actualKey].push(day);
    return acc;
  }, {});
};
export const retrieveProjects = (weeks: TimesheetWeeksType) => {
  const projects = {};

  Object.keys(weeks).forEach((weekNumber) => {
    const weekProjects = [];
    const actualKey = weekNumber;
    weeks[actualKey].forEach((day) => {
      if (day.workedOn) {
        day.workedOn.forEach((el) => {
          weekProjects.push({
            projectId: el.projectId,
          });
        });
      }
    });

    projects[weekNumber] = weekProjects.filter(
      (el, index, arr) => index === arr.findIndex((t) => t.projectId === el.projectId)
    );
  });

  return projects;
};

export const fetchTablesData = (
  weeks: TimesheetWeeksType,
  projects: { [weekNumber: string]: any[] },
  translations: Record<Timesheet.WorkTimeType, string>
) => {
  const tablesData = {};

  Object.keys(projects).forEach((weekNumber) => {
    tablesData[weekNumber] = [];

    projects[weekNumber].forEach(
      (
        {
          projectId,
          // typeOfWork
        },
        index
      ) => {
        const rowData = {
          id: `${projectId}*${_.uniqueId()}`,
          projectId,
          totalHours: 0,
          totalWeekDays: 0,
          custom: false,
          // typeOfWork,
          timesheets: [],
        };

        weeks[weekNumber].forEach((day) => {
          const { date, editable, maxValue, workTimeType, workedOn } = day;
          let hours = 0;

          if (editable) {
            rowData.totalWeekDays += maxValue;
          }

          workedOn.forEach((el) => {
            // if (el.projectId === projectId && el.typeOfWork === typeOfWork) {
            if (el.projectId === projectId) {
              hours = el.hours;

              if (editable) {
                rowData.totalHours += hours;
              }
            }
          });

          const { displayText, value } = getDayValues(workTimeType, hours, translations);

          rowData.timesheets.push({
            date,
            workTimeType,
            editable,
            maxValue,
            hours,
            displayText,
            value,
            name: `${date}.${rowData.id}`,
            key: format(new Date(date), 'ddMMM'),
          });
        });

        tablesData[weekNumber][index] = rowData;
      }
    );
  });

  return tablesData;
};

export const addProjectData = (currentWeeks: TimesheetWeeksType, tablesData: any, projectId: string) => {
  const newTablesData = {};
  Object.keys(tablesData).forEach((weekNumber) => {
    newTablesData[weekNumber] = [...tablesData[weekNumber]];

    const weekProjects = tablesData[weekNumber].find((project) => project.id.split('*')[0] == projectId);
    if (!weekProjects) {
      const weekDays = currentWeeks[weekNumber];
      const newProjectData = {
        id: `${projectId}*${_.uniqueId()}`,
        projectId,
        totalHours: 0,
        totalWeekDays: 0,
        custom: true,
        typeOfWork: '',
        timesheets: [],
      };

      weekDays.forEach((day) => {
        const { date, editable, maxValue, workTimeType } = day;
        if (editable) {
          newProjectData.totalWeekDays += maxValue;
        }

        newProjectData.timesheets.push({
          date,
          workTimeType: workTimeType,
          editable,
          maxValue,
          hours: 0,
          displayText: '0',
          value: '0',
          name: `${date}.${newProjectData.id}`,
          key: format(new Date(date), 'ddMMM'),
        });
      });

      newTablesData[weekNumber].push(newProjectData);
    }
  });

  return newTablesData;
};

export const changeWeekData = (
  tablesData: any,
  currentWeeks: TimesheetWeeksType,
  weekNumber: string,
  projects: { projectId: string; hours: { displayText: string; value: string; workTimeType: string } }[],
  currentStatistics: any
) => {
  const newTablesData = { ...tablesData };
  const newStatistics = { TOTAL: currentStatistics.TOTAL, WORK: 0 };
  const weekDays = currentWeeks[weekNumber];

  newTablesData[weekNumber] = [];

  projects.forEach(({ projectId, hours }) => {
    const projectData = {
      id: `${projectId}*${_.uniqueId()}`,
      projectId,
      totalHours: 0,
      totalWeekDays: 0,
      custom: true,
      typeOfWork: '',
      timesheets: [],
    };

    weekDays.forEach((day) => {
      const { date, editable, maxValue } = day;

      if (editable) {
        projectData.totalWeekDays += maxValue;
        projectData.totalHours += !isNaN(+hours.value) ? +hours.value : 0;
      }
      projectData.timesheets.push({
        date,
        workTimeType: hours.workTimeType,
        editable,
        maxValue,
        hours: editable && !isNaN(+hours.value) ? +hours.value : 0,
        displayText: editable ? hours.displayText : '0',
        value: editable ? hours.value : '0',
        name: `${date}.${projectData.id}`,
        key: format(new Date(date), 'ddMMM'),
      });
    });

    newTablesData[weekNumber].push(projectData);
  });

  Object.values(newTablesData).forEach((weekData: any[]) => {
    weekData.forEach((weekProject) => {
      weekProject.timesheets.forEach(({ workTimeType, hours, maxValue }) => {
        if (workTimeType === 'WORK') {
          newStatistics['WORK'] += +hours;
        } else if (newStatistics[workTimeType]) {
          newStatistics[workTimeType] += maxValue;
        } else {
          newStatistics[workTimeType] = maxValue;
        }
      });
    });
  });

  return [newTablesData, newStatistics];
};

export const deleteProjectData = (tablesData: any, weekNumber: string, id: string) => {
  const newTablesData = { ...tablesData };

  const index = newTablesData[weekNumber].findIndex((project) => project.id === id);
  newTablesData[weekNumber].splice(index, 1);

  return newTablesData;
};

export const getDayValues = (
  workTimeType: Timesheet.WorkTimeType,
  hours: number,
  translations: Record<Timesheet.WorkTimeType, string>
): { displayText: string; value: string } => {
  let displayText = '0';
  let value = '0';

  switch (workTimeType) {
    case 'DAY_OFF':
      displayText = translations.DAY_OFF.toUpperCase();
      value = translations.DAY_OFF;
      break;
    case 'SICK_LEAVE':
      displayText = translations.SICK_LEAVE.toLocaleUpperCase();
      value = translations.SICK_LEAVE;
      break;
    case 'PAID_VACATION_LEAVE':
      displayText = translations.PAID_VACATION_LEAVE.toLocaleUpperCase();
      value = translations.PAID_VACATION_LEAVE;
      break;
    case 'UNPAID_VACATION_LEAVE':
      displayText = translations.UNPAID_VACATION_LEAVE.toLocaleUpperCase();
      value = translations.UNPAID_VACATION_LEAVE;
      break;
    case 'LONG_UNPAID_LEAVE':
      displayText = translations.LONG_UNPAID_LEAVE.toLocaleUpperCase();
      value = translations.LONG_UNPAID_LEAVE;
      break;
    // case 'ABSENCE_NON_REASONABLE':
    //   displayText = translations.ABSENCE_NON_REASONABLE.toLocaleUpperCase();
    //   value = translations.ABSENCE_NON_REASONABLE;
    //   break;
    case 'BUSINESS_TRIP':
      displayText = translations.BUSINESS_TRIP.toLocaleUpperCase();
      value = translations.BUSINESS_TRIP;
      break;
    case 'MATERNITY_LEAVE':
      displayText = translations.MATERNITY_LEAVE.toLocaleUpperCase();
      value = translations.MATERNITY_LEAVE;
      break;
    case 'CHILD_CARE_LEAVE':
      displayText = translations.CHILD_CARE_LEAVE.toLocaleUpperCase();
      value = translations.CHILD_CARE_LEAVE;
      break;
    default:
      displayText = `${hours}`;
      value = `${hours}`;
      break;
  }

  return { displayText, value };
};
