import { createSlice } from '@reduxjs/toolkit';

import reducers from './contract-reducer';

export interface ContractState {
  contract: any;
  history: any[];
  salary: any[];
  errorMessage: string;
}

export const initialState: ContractState = {
  contract: null,
  history: [],
  salary: [],
  errorMessage: null,
};

export const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers,
});

export const {
  getContractStart,
  getContractSalaryStart,
  getContractHistoryStart,
  getContractSuccess,
  getContractFailure,
  getContractSalarySuccess,
  getContractSalaryFailure,
  getContractHistorySuccess,
  getContractHistoryFailure,
  createContractStart,
  createContractSuccess,
  createContractFailure,
} = contractSlice.actions;

export default contractSlice.reducer;
