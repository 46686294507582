import { AlertColor } from '@mui/lab/Alert';

import { createSlice } from '@reduxjs/toolkit';

import reducers from './loading-reducer';

export type FetchingState = 'IDLE' | 'PENDING' | 'FAILURE';

export interface LoadingState {
  fetching: Record<string, FetchingState>;
  error: Record<string, string>;
  snackbarOpen: boolean;
  snackbarSeverity: AlertColor;
  snackbarMessage: string;
}

export const initialState: LoadingState = {
  fetching: {},
  error: {},
  snackbarOpen: false,
  snackbarSeverity: 'success',
  snackbarMessage: '',
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers,
});

export const {
  setFetchingStart,
  setFetchingSuccess,
  setFetchingFailure,
  clearFetchingStatus,
  setFetchingStatus,
  setError,
  clearError,
  openSnackbar,
  closeSnackbar,
} = loadingSlice.actions;

export default loadingSlice.reducer;
