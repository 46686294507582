import { DropResult } from 'react-beautiful-dnd';

import {
  CommonErrorResponse,
  CommonSuccessResponse,
  CreateOrgStructureDTO,
  EntityRelation,
  OrgStructure,
  OrgStructureResponse,
  OrgStructureStatus,
  PlainWorkgroup,
  SetStatusDTO,
  Snapshot,
  SnapshotDetails,
  SnapshotListResponse,
  TreeStructureNode,
  UpdateOrgStructureDTO,
  Vacancy,
  Workgroup,
} from '@dms-hcms-web/api-interfaces';
import { PayloadAction } from '@reduxjs/toolkit';

import { OrgStructureState } from './org-structure-slice';
import { getFirstFreeClusterColor } from './utils';

const reducers = {
  createOrgStructureStart: (state: OrgStructureState, action: PayloadAction<CreateOrgStructureDTO>) => {
    state.errorMessage = '';
  },
  createOrgStructureSuccess: (state: OrgStructureState, action: PayloadAction<OrgStructure>) => {
    state.newOrgStructure = action.payload;
    state.item = null;
    state.workGroups = [];
  },
  createOrgStructureFailure: (state: OrgStructureState, action: PayloadAction<CommonErrorResponse>) => void {},
  fetchItemStart: (state: OrgStructureState, action: PayloadAction<string>) => {
    state.isFetching = true;
    state.errorMessage = '';
  },
  fetchItemSuccess: (state: OrgStructureState, action: PayloadAction<OrgStructureResponse>) => {
    const { orgStructure, workGroups } = action.payload;
    state.isFetching = false;
    state.item = orgStructure;
    state.workGroups = workGroups;
    state.newOrgStructure = null;
  },
  fetchItemFailure: (state: OrgStructureState, action: PayloadAction<string>) => {
    state.isFetching = false;
    state.errorMessage = action.payload;
  },
  fetchListStart: (state: OrgStructureState, action: PayloadAction<string>) => {
    state.errorMessage = '';
  },
  fetchListSuccess: (state: OrgStructureState, action: PayloadAction<OrgStructure[]>) => {
    state.list = action.payload;
  },
  fetchSnapshotListStart: (state: OrgStructureState, action: PayloadAction<string>) => {
    state.errorMessage = '';
  },
  fetchSnapshotListSuccess: (state: OrgStructureState, action: PayloadAction<Snapshot[]>) => {
    state.snapshots = action.payload;
  },
  fetchSnapshotListFailure: (state: OrgStructureState, action: PayloadAction<CommonErrorResponse>) => {
    state.errorMessage = action.payload.message;
  },
  fetchSnapshotDetailsStart: (state: OrgStructureState, action: PayloadAction<string>) => {
    state.errorMessage = '';
  },
  fetchSnapshotDetailsSuccess: (state: OrgStructureState, action: PayloadAction<SnapshotDetails>) => {
    state.snapshotDetails = action.payload;
  },
  fetchSnapshotDetailsFailure: (state: OrgStructureState, action: PayloadAction<CommonErrorResponse>) => {
    state.errorMessage = action.payload.message;
  },
  updateOrgStructureStart: (
    state: OrgStructureState,
    action: PayloadAction<{ id: string; data: Partial<UpdateOrgStructureDTO> }>
  ) => {
    state.errorMessage = '';
  },
  updateOrgStructureSuccess: (state: OrgStructureState, action: PayloadAction<string>) => {
    state.item.name = action.payload;
  },
  updateOrgStructureFailure: (state: OrgStructureState, action: PayloadAction<CommonErrorResponse>) => {
    state.errorMessage = action.payload.message;
  },
  deleteOrgStructureStart: (state: OrgStructureState, action: PayloadAction<string>) => {
    state.errorMessage = '';
  },
  deleteOrgStructureSuccess: (state: OrgStructureState, action: PayloadAction<string>) => {
    state.list = state.list.filter((item) => item.id !== action.payload);
  },
  deleteOrgStructureFailure: (state: OrgStructureState, action: PayloadAction<CommonErrorResponse>) => void {},
  setOrgStructureStatusStart: (state: OrgStructureState, action: PayloadAction<{ id: string; data: SetStatusDTO }>) => {
    state.errorMessage = '';
  },
  setOrgStructureStatusSuccess: (state: OrgStructureState, action: PayloadAction<OrgStructureStatus>) => {
    state.item.status = action.payload;
  },
  setOrgStructureStatusFailure: (state: OrgStructureState, action: PayloadAction<CommonErrorResponse>) => void {},
  duplicateOrgStructureStart: (state: OrgStructureState, action: PayloadAction<string>) => {
    state.errorMessage = '';
  },
  duplicateOrgStructureSuccess: (state: OrgStructureState, action: PayloadAction<OrgStructure>) => {
    state.list.push(action.payload);
  },
  duplicateOrgStructureFailure: (state: OrgStructureState, action: PayloadAction<CommonErrorResponse>) => void {},
  addWorkgroupStart: (state: OrgStructureState, action: PayloadAction<{ orgStructureId: string; data: Workgroup }>) => {
    state.errorMessage = '';
  },
  addWorkgroupSuccess: (state: OrgStructureState, action: PayloadAction<OrgStructure>) => {
    state.isClusterBuilderOpen = false;
  },
  addWorkgroupFailure: (state: OrgStructureState, action: PayloadAction<CommonErrorResponse>) => {
    state.errorMessage = action.payload.message;
  },
  updateWorkgroupStart: (
    state: OrgStructureState,
    action: PayloadAction<{ orgStructureId: string; data: Workgroup }>
  ) => {
    state.errorMessage = '';
  },
  updateWorkgroupSuccess: (state: OrgStructureState, action: PayloadAction<OrgStructure>) => {
    state.dragSourceParentIndex = null;
    state.dragDestinationParentIndex = null;
  },
  updateWorkgroupFailure: (state: OrgStructureState, action: PayloadAction<CommonErrorResponse>) => {
    state.dragSourceParentIndex = null;
    state.dragDestinationParentIndex = null;
    state.errorMessage = action.payload.message;
  },
  deleteWorkgroupStart: (state: OrgStructureState, action: PayloadAction<{ id: string; workgroupId: string }>) => {
    state.errorMessage = '';
  },
  deleteWorkgroupSuccess: (state: OrgStructureState, action: PayloadAction<string>) => {
    state.workGroups = state.workGroups.filter((item) => item.id !== action.payload);
    state.isClusterBuilderOpen = false;
  },
  deleteWorkgroupFailure: (state: OrgStructureState, action: PayloadAction<CommonErrorResponse>) => {
    state.errorMessage = action.payload.message;
  },
  fetchListFailure: (state: OrgStructureState, action: PayloadAction<string>) => {
    state.errorMessage = action.payload;
  },
  fetchTreeStart: (state: OrgStructureState, action: PayloadAction<string>) => {
    state.errorMessage = '';
  },
  fetchTreeSuccess: (state: OrgStructureState, action: PayloadAction<TreeStructureNode>) => {
    state.tree = action.payload;
  },
  fetchTreeFailure: (state: OrgStructureState, action: PayloadAction<string>) => {
    state.errorMessage = action.payload;
  },

  // TODO: refactor
  openClusterBuilder: (state: OrgStructureState, action: PayloadAction<number>) => {
    state.isClusterBuilderOpen = true;
    state.initialWorkgroups = [...state.workGroups];
    // if id not passed then we create a dummy cluster
    if (action.payload != null) {
      state.selectedClusterIndex = action.payload;
    } else {
      const newCluster: Workgroup = {
        id: null,
        captain: '',
        structureId: '',
        parent: '',
        color: getFirstFreeClusterColor(state.workGroups),
        entityRelation: {
          entityId: '',
          entityName: '',
          entityType: 'CLUSTER',
        },
        employees: [],
        subgroups: [],
        vacancies: [],
      };
      state.workGroups.push(newCluster);
      state.selectedClusterIndex = state.workGroups.length - 1;
    }
  },
  closeClusterBuilder: (state: OrgStructureState) => {
    state.isClusterBuilderOpen = false;
    state.selectedClusterIndex = null;
    state.workGroups = [...state.initialWorkgroups];
    state.initialWorkgroups = null;
  },
  // TODO: refactor
  openProjectBuilder: (state: OrgStructureState, action: PayloadAction<number>) => {
    state.isProjectBuilderOpen = true;
    state.initialClusterProjects = [...state.workGroups[state.selectedClusterIndex].subgroups];
    if (action.payload != null) {
      state.selectedProjectIndex = action.payload;
    } else {
      const newProject: Workgroup = {
        id: null,
        captain: '',
        structureId: '',
        parent: '',
        color: '',
        entityRelation: {
          entityId: '',
          entityName: '',
          entityType: 'PROJECT',
        },
        employees: [],
        subgroups: [],
        vacancies: [],
      };
      state.workGroups[state.selectedClusterIndex].subgroups.push(newProject);
      state.selectedProjectIndex = state.workGroups[state.selectedClusterIndex].subgroups.length - 1;
    }
  },
  closeProjectBuilder: (state: OrgStructureState) => {
    state.isProjectBuilderOpen = false;
    state.selectedProjectIndex = null;
    state.workGroups[state.selectedClusterIndex].subgroups = [...state.initialClusterProjects];
  },
  changeClusterEntityRelation: (state: OrgStructureState, action: PayloadAction<EntityRelation>) => {
    if (state.selectedClusterIndex != null) {
      state.workGroups[state.selectedClusterIndex].entityRelation = action.payload;
    }
  },
  changeProjectEntityRelation: (state: OrgStructureState, action: PayloadAction<EntityRelation>) => {
    if (state.selectedProjectIndex != null) {
      state.workGroups[state.selectedClusterIndex].subgroups[state.selectedProjectIndex].entityRelation =
        action.payload;
    }
  },
  markWorkgroupCaptain: (state: OrgStructureState, action: PayloadAction<{ id: string; workGroupType: any }>) => {
    if (state.selectedClusterIndex == null) {
      return;
    }
    const { id, workGroupType } = action.payload;
    if (workGroupType === 'CLUSTER') {
      state.workGroups[state.selectedClusterIndex].captain = id;
    } else {
      state.workGroups[state.selectedClusterIndex].subgroups[state.selectedProjectIndex].captain = id;
    }
  },
  changeClusterColor: (state: OrgStructureState, action: PayloadAction<string>) => {
    if (state.selectedClusterIndex != null) {
      state.workGroups[state.selectedClusterIndex].color = action.payload;
    }
  },
  saveProject: (state: OrgStructureState, action: PayloadAction<Workgroup>) => {
    state.isProjectBuilderOpen = false;
  },
  deleteProject: (state: OrgStructureState, action: PayloadAction<Workgroup>) => {
    state.workGroups[state.selectedClusterIndex]?.subgroups.splice(state.selectedProjectIndex, 1);
    state.isProjectBuilderOpen = false;
  },
  addClusterEmployee: (state: OrgStructureState, action: PayloadAction<any>) => {
    for (const item of state.workGroups[state.selectedClusterIndex]?.employees) {
      if (!action.payload || action.payload.id === item.id) return;
    }
    state.workGroups[state.selectedClusterIndex]?.employees.push(action.payload);
  },
  removeClusterEmployee: (state: OrgStructureState, action: PayloadAction<number>) => {
    state.workGroups[state.selectedClusterIndex]?.employees.splice(action.payload, 1);
  },
  addProjectEmployee: (state: OrgStructureState, action: PayloadAction<any>) => {
    for (const item of state.workGroups[state.selectedClusterIndex]?.subgroups[state.selectedProjectIndex].employees) {
      if (!action.payload || action.payload.id === item.id) return;
    }
    state.workGroups[state.selectedClusterIndex]?.subgroups[state.selectedProjectIndex].employees.push(action.payload);
  },
  removeProjectEmployee: (state: OrgStructureState, action: PayloadAction<number>) => {
    state.workGroups[state.selectedClusterIndex]?.subgroups[state.selectedProjectIndex].employees.splice(
      action.payload,
      1
    );
  },
  addClusterVacancy: (state: OrgStructureState, action: PayloadAction<Vacancy>) => {
    if (!action.payload) return;
    state.workGroups[state.selectedClusterIndex]?.vacancies.push(action.payload);
  },
  removeClusterVacancy: (state: OrgStructureState, action: PayloadAction<number>) => {
    state.workGroups[state.selectedClusterIndex]?.vacancies.splice(action.payload, 1);
  },
  addProjectVacancy: (state: OrgStructureState, action: PayloadAction<Vacancy>) => {
    if (!action.payload) return;
    state.workGroups[state.selectedClusterIndex]?.subgroups[state.selectedProjectIndex].vacancies.push(action.payload);
  },
  removeProjectVacancy: (state: OrgStructureState, action: PayloadAction<number>) => {
    state.workGroups[state.selectedClusterIndex]?.subgroups[state.selectedProjectIndex].vacancies.splice(
      action.payload,
      1
    );
  },
  moveClusterEmployee: (state: OrgStructureState, action: PayloadAction<DropResult>) => {
    const { destination, source } = action.payload;

    // Clusters do not have parent indexes
    const [sourceParentIndex, sourceIndex] = source.droppableId.split('%%');
    const [destinationParentIndex, destinationIndex] = destination.droppableId.split('%%');

    const [item] = state.workGroups[sourceIndex].employees.splice(source.index, 1);
    state.workGroups[destinationIndex]?.employees.splice(destination.index, 0, item);
    state.dragSourceParentIndex = +sourceIndex;
    state.dragDestinationParentIndex = +destinationIndex;
  },
  moveProjectEmployee: (state: OrgStructureState, action: PayloadAction<DropResult>) => {
    const { destination, source } = action.payload;

    const [sourceParentIndex, sourceIndex] = source.droppableId.split('%%');
    const [destinationParentIndex, destinationIndex] = destination.droppableId.split('%%');
    // Distinguish id's for same employee in different workgroups

    const [item] = state.workGroups[sourceParentIndex].subgroups[sourceIndex]?.employees.splice(source.index, 1);
    state.workGroups[destinationParentIndex].subgroups[destinationIndex]?.employees.splice(destination.index, 0, item);
    state.dragSourceParentIndex = +sourceParentIndex;
    state.dragDestinationParentIndex = +destinationParentIndex;
  },
  moveProject: (state: OrgStructureState, action: PayloadAction<DropResult>) => {
    const { destination, source, draggableId } = action.payload;

    // Pass indexes through DropResult
    const [sourceParentIndex] = source.droppableId.split('%%');
    const [sourceIndex] = draggableId.split('%%');
    const [destinationParentIndex] = destination.droppableId.split('%%');

    const [item] = state.workGroups[sourceParentIndex].subgroups.splice(sourceIndex, 1);
    state.workGroups[destinationParentIndex].subgroups.push(item);
    state.dragSourceParentIndex = +sourceParentIndex;
    state.dragDestinationParentIndex = +destinationParentIndex;
  },
  getShortInfoStart: (state: OrgStructureState, action: PayloadAction) => {
    state.errorMessage = '';
  },
  getShortInfoSuccess: (state: OrgStructureState, action: PayloadAction<any[]>) => {
    state.employeers = action.payload;
  },
};

export default reducers;
