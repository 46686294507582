import { all, call, put, takeEvery } from 'redux-saga/effects';

import { PayloadAction } from '@reduxjs/toolkit';

import { createContract, getContract, getHistory, getSalary } from '../../services/contract';
import { setFetchingFailure, setFetchingStart, setFetchingSuccess } from '../loading/loading-slice';
import {
  createContractFailure,
  createContractStart,
  createContractSuccess,
  getContractFailure,
  getContractHistoryFailure,
  getContractHistoryStart,
  getContractHistorySuccess,
  getContractSalaryFailure,
  getContractSalaryStart,
  getContractSalarySuccess,
  getContractStart,
  getContractSuccess,
} from './contract-slice';

export function* getContractAsync(
  action: PayloadAction<{
    id: string;
  }>
) {
  yield put(setFetchingStart(action.type));
  try {
    const res = yield getContract({ id: action.payload.id });
    yield put(getContractSuccess(res?.data));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(getContractFailure(error?.message));
    yield put(setFetchingFailure(action.type));
  }
}

export function* createContractAsync(
  action: PayloadAction<{
    contract: any;
  }>
) {
  yield put(setFetchingStart(action.type));
  try {
    const res = yield createContract(action.payload.contract);
    yield put(createContractSuccess(res?.data));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(createContractFailure(error?.message));
    yield put(setFetchingFailure(action.type));
  }
}
export function* getContractSalaryAsync(
  action: PayloadAction<{
    employeeId: string;
  }>
) {
  yield put(setFetchingStart(action.type));
  try {
    const res = yield getSalary({ employeeId: action.payload.employeeId });
    yield put(getContractSalarySuccess(res?.data));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(getContractSalaryFailure(error?.message));
    yield put(setFetchingFailure(action.type));
  }
}
export function* getContractHistoryAsync(
  action: PayloadAction<{
    employeeId: string;
  }>
) {
  yield put(setFetchingStart(action.type));
  try {
    const res = yield getHistory({ employeeId: action.payload.employeeId });
    yield put(getContractHistorySuccess(res?.data));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(getContractHistoryFailure(error?.message));
    yield put(setFetchingFailure(action.type));
  }
}
export function* onGetContractStart() {
  yield takeEvery(getContractStart.type, getContractAsync);
}
export function* onGetContractSalaryStart() {
  yield takeEvery(getContractSalaryStart.type, getContractSalaryAsync);
}
export function* onGetContractHistoryStart() {
  yield takeEvery(getContractHistoryStart.type, getContractHistoryAsync);
}
export function* onCreateContractStart() {
  yield takeEvery(createContractStart.type, createContractAsync);
}

export function* contractSagas() {
  yield all([
    call(onGetContractStart),
    call(onGetContractHistoryStart),
    call(onGetContractSalaryStart),
    call(onCreateContractStart),
  ]);
}
