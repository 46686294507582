import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button as MuiButton } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Button = styled(MuiButton)`
  padding-left: 0;
`;

type Props = {
  text: string;
};

const BackButton: React.FC<Props> = ({ text }) => {
  const history = useHistory();

  const handleClick = () => {
    history.goBack();
  };
  return (
    <Button
      startIcon={<ArrowBackIosIcon sx={{ width: '12px', height: '12px' }} />}
      variant="text"
      onClick={handleClick}
    >
      {text}
    </Button>
  );
};

export { BackButton };
