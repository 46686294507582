import { PayloadAction } from '@reduxjs/toolkit';

import { CommentsState } from './comments-slice';

const reducers = {
  fetchCommentsStart: (state: CommentsState, action: PayloadAction<Record<string, string>>) => {
    //
  },
  fetchCommentsSuccess: (state: CommentsState, action: PayloadAction<any[]>) => {
    state.list = action.payload;
  },
  fetchCommentsFailure: (state: CommentsState, action: PayloadAction<string>) => {
    state.list = [];
    state.errorMessage = action.payload;
  },
  addCommentStart: (
    state: CommentsState,
    action: PayloadAction<{ id: string; text: string; employeeId: string; profileId: string; companyId?: string }>
  ) => {
    //;
  },
  addCommentSuccess: (state: CommentsState, action: PayloadAction<any[]>) => {
    state.list = action.payload;
  },
  addCommentFailure: (state: CommentsState, action: PayloadAction<string>) => {
    state.errorMessage = action.payload;
  },
};

export default reducers;
