import { combineReducers } from 'redux';

import commentsReducer from './comments/comments-slice';
import contractReducer from './contract/contract-slice';
import employeeReducer from './employee/employee-slice';
import loadingReducer from './loading/loading-slice';
import orgStructureReducer from './org-structure/org-structure-slice';
import timsheetReducer from './timesheets/timesheets-slice';

const rootReducer = combineReducers({
  timesheets: timsheetReducer,
  orgStructure: orgStructureReducer,
  comments: commentsReducer,
  loading: loadingReducer,
  employee: employeeReducer,
  contract: contractReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
