import { environment } from '@environment';

import httpClient from './http';

const getAllNotifications = (userId: string) => {
  return httpClient.get(`${environment.baseApiUrl}/notifications/all`, { params: { userId } });
};

const getNotification = (id: string) => {
  return httpClient.get(`${environment.baseApiUrl}/notifications/${id}`);
};

const getNotificationsByStatus = (userId: string, read: boolean) => {
  return httpClient.get(`${environment.baseApiUrl}/notifications/status`, { params: { userId, read } });
};

const updateNotificationStatus = (notificationId: string, read: boolean) => {
  return httpClient.post(`${environment.baseApiUrl}/notifications/status`, null, { params: { notificationId, read } });
};

export const notificationService = {
  getAllNotifications,
  getNotification,
  getNotificationsByStatus,
  updateNotificationStatus,
};
