/* eslint-disable react-hooks/rules-of-hooks */
import { Employee, EmployeeItem } from '@dms-hcms-web/api-interfaces';

import { useWorkspace } from '@hooks/useWorkspace';

import { environment } from '@environment';

import httpClient from './http';

export const getEmployeeItem = ({ employeeId }: Omit<Employee.GetEmployeeItem.QueryParams, 'companyId'>) => {
  const [company] = useWorkspace();
  return httpClient.get<Employee.GetEmployeeItem.ResponseDto>(
    `${environment.baseUrl}/hcms/main-api/employee/${employeeId}`,
    {
      params: {
        companyId: company.id,
      },
    }
  );
};

export const getShortInfo = ({ employeeId }: Omit<Employee.GetShortInfo.QueryParams, 'companyId'>) => {
  const [company] = useWorkspace();
  return httpClient.get<Employee.GetShortInfo.ResponseDto>(
    `${environment.baseApiUrl}/employees/${employeeId}/short-info`,
    {
      params: {
        companyId: company.id,
      },
    }
  );
};

export const updateEmployeeItem = ({
  employeeId,
  body,
}: Omit<Employee.UpdateEmployeeItem.RequestDto, 'workspaceId'>) => {
  const [company] = useWorkspace();
  return httpClient.put<Employee.UpdateEmployeeItem.ResponseDto>(
    `${environment.baseApiUrl}/employees/${employeeId}`,
    body,
    {
      params: {
        workspaceId: company.id,
      },
    }
  );
};

export const updateEmployeeInfo = (
  { employeeId }: Employee.UpdateEmployeeInfo.QueryParams,
  body: Employee.UpdateEmployeeInfo.RequestDto
) => {
  const [company] = useWorkspace();
  return httpClient.put<Employee.UpdateEmployeeInfo.ResponseDto>(
    `${environment.baseUrl}/hcms/main-api/employee/profile/${employeeId}`,
    body,
    {
      params: {
        workspaceId: company.id,
      },
    }
  );
};

export const updateEmployeeIinAndPersonnelNumber = ({
  iin,
  personnelNumber,
  employeeId,
}: {
  iin: string;
  personnelNumber: string;
  employeeId: string;
}) => {
  return httpClient.put(`${environment.baseUrl}/hcms/main-api/employee/${employeeId}/personal`, {
    iin,
    personnelNumber,
  });
};

export const getStatusList = (field: Employee.GetStatusList.QueryParams) => {
  const [company] = useWorkspace();
  return httpClient.get<Employee.GetStatusList.ResponseDto>(`${environment.baseApiUrl}/employees/meta/status`, {
    params: {
      companyId: company.id,
      field,
    },
  });
};

export const addEmployee = (data: Employee.CreateEmployee.RequestDto) => {
  const [company] = useWorkspace();
  data.companyId = company.id;
  return httpClient.post<Employee.CreateEmployee.ResponseDto>(`${environment.baseApiUrl}/employees/shortinfo`, data, {
    params: {
      companyId: company.id,
    },
  });
};

export const createEmployeeEmail = (data: Employee.CreateEmployeeEmail.RequestDto) => {
  const [company] = useWorkspace();
  return httpClient.post<Employee.CreateEmployeeEmail.ResponseDto>(
    `${environment.baseApiUrl}/employees/email-address`,
    data,
    {
      params: {
        companyId: company.id,
      },
    }
  );
};

//NO SWAGGER
export const getTerminationStatus = () => {
  const [company] = useWorkspace();
  return httpClient.get(`${environment.baseApiUrl}/employees/termination`, {
    params: {
      companyId: company.id,
    },
  });
};

//NO SWAGGER
export const terminateEmployeeItem = (data: Employee.TerminateEmployeeItem.RequestDto) => {
  const [company] = useWorkspace();
  return httpClient.post<Employee.TerminateEmployeeItem.ResponseDto>(
    `${environment.baseApiUrl}/employees/termination`,
    data,
    {
      params: {
        companyId: company.id,
      },
    }
  );
};

export const uploadEmployeeImage = ({ file, employeeId }: Employee.UploadEmployeeImage.RequestDto) => {
  const formData = new FormData();
  formData.append('file', file);
  return httpClient.put<Employee.UploadEmployeeImage.ResponseDto>(
    `${environment.baseUrl}/hcms/main-api/employee/${employeeId}/update/avatar`,
    formData
  );
};
//NO USE
export const initializeExport = () => {
  const [company] = useWorkspace();
  const data = {};
  return httpClient.post<Employee.InitializeExport.ResponseDto>(`${environment.baseApiUrl}/employees/export`, data, {
    params: {
      companyId: company.id,
    },
  });
};

//NO USE
export const getExportLink = ({ employeeId }: Omit<Employee.GetExportLink.QueryParams, 'companyId'> | any) => {
  const [company] = useWorkspace();
  return httpClient.get<Employee.GetExportLink.ResponseDto>(
    `${environment.baseApiUrl}/employees/export/${employeeId}`,
    {
      params: {
        companyId: company.id,
      },
    }
  );
};
//NO SWAGGER
export const getTimeOffStatus = () => {
  const [company] = useWorkspace();
  return httpClient.get<Employee.GetTimeOffStatus.ResponseDto>(`${environment.baseApiUrl}/employees/time-off`, {
    params: {
      companyId: company.id,
    },
  });
};

export const getEmployeeWorkInfo = ({
  employeeId,
  status,
}: Omit<Employee.GetEmployeeWorkInfo.QueryParams, 'companyId'>) => {
  const [company] = useWorkspace();
  return httpClient.get<Employee.GetEmployeeWorkInfo.ResponseDto>(
    `${environment.baseApiUrl}/employees/${employeeId}/work-info`,
    {
      params: {
        companyId: company.id,
        status,
      },
    }
  );
};
//NO SWAGGER
export const getEmployeeDocuments = ({
  employeeId,
}: Omit<Employee.GetEmployeeDocuments.QueryParams, 'companyId'> | any) => {
  const [company] = useWorkspace();
  return httpClient.get<Employee.GetEmployeeDocuments.ResponseDto>(
    `${environment.baseApiUrl}/employees/${employeeId}/documents`,
    {
      params: {
        companyId: company.id,
      },
    }
  );
};
//NO SWAGGER
export const getAttachmentFile = (fileUrl: Employee.GetAttachmentFile.QueryParams | any) => {
  return httpClient.get<Employee.GetAttachmentFile.ResponseDto>(fileUrl, { responseType: 'blob' });
};
//NO SWAGGER
export const uploadFileToGoogleDrive = async (file: File) => {
  const [company] = useWorkspace();
  const myHeaders = new Headers();
  const auth = JSON.parse(localStorage.getItem('dms-auth'));
  myHeaders.append('Authorization', `Bearer ${auth?.id_token}`);

  const formdata = new FormData();
  formdata.append('file', file, file?.name);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow' as RequestRedirect,
  };

  return fetch(
    `${environment.baseUrl}/hcms/file-uploads/google-drive?companyId=${company.id}&googleDriveFolderType=PROFILE_DOCUMENTS_FOLDER`,
    requestOptions
  )
    .then(async (response) => JSON.parse(await response.text()) as any)
    .catch((err) => console.log(err));
};

//NO SWAGGER
export const updateEmployeeDocuments = ({
  employeeId,
  body,
}: Omit<Employee.UpdateEmployeeDocuments.RequestDto, 'companyId'> | any) => {
  const [company] = useWorkspace();
  return httpClient.put<Employee.UpdateEmployeeDocuments.ResponseDto>(
    `${environment.baseApiUrl}/employees/${employeeId}/documents`,
    body,
    {
      params: {
        companyId: company.id,
      },
    }
  );
};

export const getEmployeePositionsAndSalaries = (body: Employee.GetEmployeePositionsAndSalaries.RequestDto) => {
  const [company] = useWorkspace();
  return httpClient.post<Employee.GetEmployeePositionsAndSalaries.ResponseDto>(
    `${environment.baseApiUrl}/employees/positions-and-salaries`,
    body,
    {
      params: {
        companyId: company.id,
      },
    }
  );
};

export const updateEmployeePositionsAndSalaries = (data: Employee.UpdateEmployeePositionsAndSalaries.RequestDto) => {
  const [company] = useWorkspace();
  return httpClient.put<Employee.UpdateEmployeePositionsAndSalaries.ResponseDto>(
    `${environment.baseApiUrl}/structure/positions-and-salaries?companyId=${company.id}`,
    data
  );
};

//NO SWAGGER
export const getEmployeeSalaryInfo = ({
  employeeId,
}: Omit<Employee.GetEmployeeSalaryInfo.ResponseDto, 'companyId'> | any) => {
  const [company] = useWorkspace();
  return httpClient.get<Employee.GetEmployeeSalaryInfo.ResponseDto>(
    `${environment.baseApiUrl}/employees/${employeeId}/salary-info`,
    {
      params: {
        companyId: company.id,
      },
    }
  );
};

//NO SWAGGER
export const updateEmployeeBasicInfo = ({
  employeeId,
  body,
}: Omit<Employee.UpdateEmployeeBasicInfo.ResponseDto, 'companyId'> | any) => {
  const [company] = useWorkspace();
  return httpClient.put<Employee.UpdateEmployeeBasicInfo.ResponseDto>(
    `${environment.baseApiUrl}/employees/${employeeId}/basic-info`,
    body,
    {
      params: {
        companyId: company.id,
      },
    }
  );
};

//NO SWAGGER
export const updateEmployeePersonalInfo = ({
  employeeId,
  body,
}: Omit<Employee.UpdateEmployeePersonalInfo.ResponseDto, 'companyId'> | any) => {
  const [company] = useWorkspace();
  return httpClient.put<Employee.UpdateEmployeePersonalInfo.ResponseDto>(
    `${environment.baseApiUrl}/employees/${employeeId}/personal-info`,
    body,
    {
      params: {
        companyId: company.id,
      },
    }
  );
};

//NO SWAGGER
export const getEmployeePersonalAttachments = ({
  employeeId,
}: any | Omit<Employee.GetEmployeePersonalAttachments.QueryParams, 'companyId'>) => {
  const [company] = useWorkspace();
  return httpClient.get<Employee.GetEmployeePersonalAttachments.ResponseDto>(
    `${environment.baseApiUrl}/employees/${employeeId}/attachments/personal`,
    {
      params: {
        companyId: company.id,
      },
    }
  );
};

//NO SWAGGER
export const uploadEmployeePersonalAttachment = ({
  employeeId,
  attachmentType,
  file,
}: Employee.UploadEmployeePersonalAttachment.RequestDto | any) => {
  const [company] = useWorkspace();
  const myHeaders = new Headers();
  const auth = JSON.parse(localStorage.getItem('dms-auth'));
  myHeaders.append('Authorization', `Bearer ${auth?.id_token}`);

  const formdata = new FormData();
  formdata.append('file', file, file?.name);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow' as RequestRedirect,
  };

  return fetch(
    `${environment.baseUrl}/hcms/employees/${employeeId}/attachments/personal/${attachmentType}/upload?companyId=${company.id}`,
    requestOptions
  )
    .then(async (response) => JSON.parse(await response.text()) as any)
    .catch((err) => console.log(err));
};

//NO SWAGGER
export const createEmployeePersonalAttachment = ({
  employeeId,
  attachmentType,
  data,
}: Employee.CreateEmployeePersonalAttachment.RequestDto | any) => {
  const [company] = useWorkspace();
  return httpClient.post<Employee.CreateEmployeePersonalAttachment.ResponseDto>(
    `${environment.baseApiUrl}/employees/${employeeId}/attachments/personal`,
    data,
    {
      params: {
        companyId: company.id,
        attachmentType,
      },
    }
  );
};

//NO SWAGGER
export const deleteEmployeePersonalAttachment = ({
  employeeId,
  attachmentId,
}: Employee.DeleteEmployeePersonalAttachment.RequestDto | any) => {
  const [company] = useWorkspace();
  return httpClient.delete<Employee.DeleteEmployeePersonalAttachment.ResponseDto>(
    `${environment.baseApiUrl}/employees/${employeeId}/attachments/personal`,
    {
      params: {
        companyId: company.id,
        attachmentId,
      },
    }
  );
};

export const exportEmployees = (isExternal: boolean) => {
  const [company] = useWorkspace();
  const myHeaders = new Headers();
  const auth = JSON.parse(localStorage.getItem('dms-auth'));
  const workspaceToken = localStorage.getItem('workspace_token');

  myHeaders.append('Authorization', `Bearer ${auth?.id_token}`);
  myHeaders.append('Workspace-Authorization', workspaceToken);
  myHeaders.append('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: null,
  };

  return fetch(
    `${environment.baseUrl}/hcms/main-api/employee${isExternal ? '/external' : ''}/export?companyId=${company.id}`,
    requestOptions
  );
};
//NO SWAGGER
export const updateUser = ({ employeeId, body }: Employee.UpdateUser.RequestDto | any) => {
  return httpClient
    .put<Employee.UpdateUser.ResponseDto>(`${environment.baseApiUrl}/employees/update/users/${employeeId}`, body)
    .then((res) => res.data);
};

export const getEmployeesList = (ids: string[]) => {
  return httpClient
    .post<Employee.GetEmployeesList.ResponseDto>(`${environment.baseApiUrl}/employees/in-list`, ids)
    .then((res) => res.data);
};

export const getAllEmployees = () => {
  const [{ id }] = useWorkspace();
  return httpClient
    .put<EmployeeItem[]>(`${environment.baseUrl}/hcms/main-api/structure/in-holding`, { workspaceIds: [id] })
    .then((res) => res.data);
};
