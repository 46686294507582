import { PlainWorkgroup, Workgroup } from '@dms-hcms-web/api-interfaces';

import { colors } from '../../shared/constants';

export const transformToPlainWorkgroup = ({
  id,
  structureId,
  parent,
  captain,
  color,
  entityRelation,
  employees,
  vacancies,
  subgroups,
}: Workgroup) => {
  const data: PlainWorkgroup = {
    structureId,
    parent,
    entityRelation,
    color,
    captain,
    employees: employees.map((item) => item.id),
    vacancies: vacancies.map((item) => item.id),
    subgroups: subgroups.map((item) => transformToPlainWorkgroup(item)),
  };

  if (id) data.id = id;

  return data;
};

export const getFirstFreeClusterColor = (workgroups: Workgroup[]) => {
  const busyColors = new Set<string>();
  for (const { color } of workgroups) {
    busyColors.add(color.toLowerCase());
  }
  for (const color of colors) {
    if (!busyColors.has(color.toLowerCase())) {
      return color;
    }
  }
  return '';
};
