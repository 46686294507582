import { PayloadAction } from '@reduxjs/toolkit';

import { ContractState } from './contract-slice';

const reducers = {
  getContractStart: (state: ContractState, action: PayloadAction<{ id: string }>) => {
    state.errorMessage = null;
  },
  getContractHistoryStart: (state: ContractState, action: PayloadAction<{ employeeId: string }>) => {
    state.errorMessage = null;
  },
  getContractSalaryStart: (state: ContractState, action: PayloadAction<{ employeeId: string }>) => {
    state.errorMessage = null;
  },
  getContractSuccess: (state: ContractState, action: PayloadAction<any>) => {
    state.contract = action.payload;
  },

  getContractFailure: (state: ContractState, action: PayloadAction<string>) => {
    state.contract = null;
    state.errorMessage = action.payload;
  },
  getContractHistorySuccess: (state: ContractState, action: PayloadAction<any>) => {
    state.history = action.payload;
  },

  getContractHistoryFailure: (state: ContractState, action: PayloadAction<string>) => {
    state.errorMessage = action.payload;
  },
  getContractSalarySuccess: (state: ContractState, action: PayloadAction<any>) => {
    state.salary = action.payload;
  },

  getContractSalaryFailure: (state: ContractState, action: PayloadAction<string>) => {
    state.errorMessage = action.payload;
  },
  createContractStart: (state: ContractState, action: PayloadAction<{ contract: any }>) => {
    state.errorMessage = null;
  },

  createContractSuccess: (state: ContractState, action: PayloadAction<any>) => {
    state.contract = action.payload;
  },

  createContractFailure: (state: ContractState, action: PayloadAction<string>) => {
    state.errorMessage = action.payload;
  },
};

export default reducers;
