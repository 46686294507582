/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useMemo, useState } from 'react';

type FilterContextState = {
  values: Record<string, Record<string, any>>;
};
type FilterContextCommandType = {
  setFilter: React.Dispatch<React.SetStateAction<FilterContextState['values']>>;
};

type FilterCurrentCommandType = {
  delete: (key: string) => void;
  setCurrentFilter: (newFilterData: Record<string, any>) => void;
  updateCreateValue: (key: string, value: any) => void;
  clearFilter: () => void;
};
const FilterContext = createContext({} as FilterContextState);
export const useFilterContext = () => useContext(FilterContext);

/**Get specific filter from the main state*/
export const useCurrentFilterState = (id: string) => {
  const { values } = useFilterContext();
  return values[id] || {};
};

const FilterContextCommand = createContext({} as FilterContextCommandType);
export const useFilterContextCommand = () => useContext(FilterContextCommand);

/**Get controlling functions for specific filter */
export const useCurrentFilterCommand = (id: string) => {
  const command = useFilterContextCommand();
  return useMemo<FilterCurrentCommandType>(
    () => ({
      setCurrentFilter: (newState) => {
        command.setFilter((prev) => ({ ...prev, [id]: newState }));
      },
      delete: (key) =>
        command.setFilter((prev) => {
          delete prev[id][key];
          return { ...prev };
        }),

      updateCreateValue: (key, value) => {
        command.setFilter((prev) => ({ ...prev, [id]: { ...prev[id], [key]: value } }));
      },
      clearFilter: () =>
        command.setFilter((prev) => {
          delete prev[id];
          return prev;
        }),
    }),
    [command, id]
  );
};

/**Context for storing filtering data
 *
 * Stores object with ids as keys, and stores another object for filtering data.
 * This allows us to store filtering data for different components without overlapping
 *
 */
export const FilterContextProvider = ({ children }) => {
  const [filter, setFilter] = useState<FilterContextState['values']>({});

  const command = useMemo<FilterContextCommandType>(() => ({ setFilter }), []);

  const state = useMemo(
    () => ({
      values: filter,
    }),
    [filter]
  );
  return (
    <FilterContextCommand.Provider value={command}>
      <FilterContext.Provider value={state}>{children}</FilterContext.Provider>
    </FilterContextCommand.Provider>
  );
};
