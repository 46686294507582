import { Timesheet } from '@dms-hcms-web/api-interfaces';

import { createSlice } from '@reduxjs/toolkit';

import { widgetValues } from '@utils/timesheet';

import reducers from './timesheets-reducer';
import { TimesheetWeeksType } from './utils';

export interface TimesheetsState {
  editModeOn: boolean;
  currentStatistics: { [key: string]: number };
  currentStatus: Timesheet.TimesheetStatus;
  currentDays: Timesheet.TimesheetDay[];
  currentWeeks: TimesheetWeeksType;
  currentMonth: string;
  currentProjects: any;
  tablesData: any;
  temp: any;
  notifications: Timesheet.TimesheetBadge;
  translations: Record<Timesheet.WorkTimeType, string>;
}

export const initialState: TimesheetsState = {
  editModeOn: false,
  currentStatistics: null,
  currentStatus: null,
  currentDays: [],
  currentWeeks: null,
  currentMonth: null,
  currentProjects: null,
  tablesData: [],
  temp: null,
  notifications: null,
  translations: widgetValues,
};

export const timesheetsSlice = createSlice({
  name: 'timesheets',
  initialState,
  reducers,
});

export const {
  fetchMonthByIdStart,
  fetchMonthByIdSuccess,
  setEditMode,
  saveTimesheetStart,
  saveTimesheetSuccess,
  setTimesheetStatusStart,
  setTimesheetStatusSuccess,
  addProject,
  deleteProject,
  setTimesheetsTemp,
  setCurrentProjects,
  fetchTimesheetNotificationsStart,
  fetchTimesheetsNotificationsSuccess,
  setWeekTablesData,
  setWeekProjects,
  setTranslations,
} = timesheetsSlice.actions;

export default timesheetsSlice.reducer;
