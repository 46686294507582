import { Employee, StaffListFilter } from '@dms-hcms-web/api-interfaces';
import { createSlice } from '@reduxjs/toolkit';

import reducers from './employee-reducer';

export interface EmployeeState {
  list: Employee.EmployeeStructureItemDto[];
  listMinified: Employee.EmployeeMinified[];
  employeeItem: Employee.EmployeeDataItem;
  shortInfo: Employee.EmployeeShortInfo;
  basicInfo: Employee.EmployeeBasicInfo;
  personalInfo: Employee.EmployeeDataItem;
  personalAttachments: Employee.EmployeePersonalAttachments;
  workInfo: Employee.EmployeeWorkInfo;
  salaryInfo: Employee.EmployeeSalaryInfo[];
  documents: Employee.EmployeeDocuments;
  createdEmail: string;
  totalEmployees: number;
  contractList: StaffListFilter[];
  statusList: StaffListFilter[];
  rolesList: StaffListFilter[];
  isEmployeeBuilderOpen: boolean;
  terminationStatus: Employee.TerminateEmployee.ResponseDto;
  timeOffStatus: Employee.TimeOffStatusResponse;
  editList: Employee.EmployeePositionAndSalaryData[];
  editListIds: string[];
  isFiltered: boolean;
  errorMessage: string;
}

export const initialState: EmployeeState = {
  list: [],
  listMinified: [],
  employeeItem: null,
  shortInfo: {} as Employee.EmployeeShortInfo,
  basicInfo: null,
  personalInfo: null,
  personalAttachments: null,
  workInfo: null,
  salaryInfo: [],
  documents: null,
  createdEmail: null,
  totalEmployees: 0,
  contractList: [],
  statusList: [],
  rolesList: [],
  isEmployeeBuilderOpen: false,
  terminationStatus: null,
  timeOffStatus: null,
  editList: [],
  editListIds: [],
  isFiltered: false,
  errorMessage: null,
};

export const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers,
});

export const {
  fetchListStart,
  fetchListSuccess,
  fetchMinifiedListSuccess,
  fetchListFailure,
  fetchEmployeeItemStart,
  fetchEmployeeItemSuccess,
  fetchEmployeeItemFailure,
  fetchEmployeeShortInfoStart,
  fetchEmployeeShortInfoSuccess,
  fetchEmployeeShortInfoFailure,
  fetchContractListSuccess,
  fetchContractListFailure,
  fetchStatusListStart,
  fetchStatusListSuccess,
  fetchStatusListFailure,
  addEmployeeStart,
  addEmployeeSuccess,
  addEmployeeFailure,
  createEmployeeEmailStart,
  createEmployeeEmailSuccess,
  createEmployeeEmailFailure,
  openEmployeeBuilder,
  closeEmployeeBuilder,
  updateEmployeeItemStart,
  updateEmployeeItemSuccess,
  updateEmployeeItemFailure,
  updateEmployeeDocumentsFailure,
  updateEmployeeDocumentsSuccess,
  updateEmployeeDocumentsStart,
  fetchEmployeeBasicInfo,
  fetchEmployeePersonalInfo,
  fetchEmployeeWorkInfoStart,
  fetchEmployeeWorkInfoSuccess,
  fetchEmployeeWorkInfoFailure,
  fetchEmployeeDocumentsStart,
  fetchEmployeeDocumentsSuccess,
  fetchEmployeeDocumentsFailure,
  terminateEmployeeItemStart,
  terminateEmployeeItemSuccess,
  fetchTerminationStatusStart,
  fetchTerminationStatusSuccess,
  fetchTerminationStatusFailure,
  fetchTimeOffStatusStart,
  fetchTimeOffStatusSuccess,
  fetchTimeOffStatusFailure,
  setEmployeeEditListIds,
  fetchEmployeePositionsAndSalariesStart,
  fetchEmployeePositionsAndSalariesSuccess,
  fetchEmployeePositionsAndSalariesFailure,
  updateEmployeePositionsAndSalariesFailure,
  updateEmployeePositionsAndSalariesSuccess,
  updateEmployeePositionsAndSalariesStart,
  fetchEmployeeSalaryInfoStart,
  fetchEmployeeSalaryInfoSuccess,
  fetchEmployeeSalaryInfoFailure,
  updateEmployeeBasicInfoStart,
  updateEmployeeBasicInfoSuccess,
  updateEmployeeBasicInfoFailure,
  updateEmployeePersonalInfoStart,
  updateEmployeePersonalInfoSuccess,
  updateEmployeePersonalInfoFailure,
  fetchEmployeePersonalAttachmentsStart,
  fetchEmployeePersonalAttachmentsSuccess,
  fetchEmployeePersonalAttachmentsFailure,
  createEmployeePersonalAttachmentStart,
  createEmployeePersonalAttachmentSuccess,
  createEmployeePersonalAttachmentFailure,
  deleteEmployeePersonalAttachmentStart,
  deleteEmployeePersonalAttachmentSuccess,
  deleteEmployeePersonalAttachmentFailure,
  fetchNewHcmsList,
  fetchNewHcmsListSuccess,
  fetchStaffListFiltersStart,
  fetchStaffListFiltersSuccess,
  fetchStaffListFiltersFailure,
} = employeeSlice.actions;

export default employeeSlice.reducer;
