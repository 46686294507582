import { Toaster } from '@dartech/dms-ui';
// import { LocalizationProvider } from '@mui/lab';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material/styles';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LoginCallback, Security } from '@okta/okta-react';
import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import HeaderWrapper from '@components/header-wrapper/HeaderWrapper';

import { BreadcrumbProvider } from '@utils/breadcrumbs';

import { Spinner } from '@shared/components/spinner';

import oktaAuth from '@services/okta';

import { DateFnsLocaleContextProvider } from '@contexts/date-fns-locale-context';
import { FilterContextProvider } from '@contexts/filter-context';
import { NotificationContextProvider } from '@contexts/notifications-context';

import '../i18n/i18n';
import GlobalStyle from './globalStyles';
import { Styled } from './styles';
import theme from './theme';

const LoginPage = lazy(() => import('./pages/login/login').then((m) => ({ default: m.LoginPage })));
const TimesheetsPage = lazy(() => import('./pages/timesheets/timesheets').then((m) => ({ default: m.TimesheetsPage })));
const ForbiddenPage = lazy(() => import('./pages/forbidden/forbidden').then((m) => ({ default: m.ForbiddenPage })));
const ProfilePage = lazy(() => import('./pages/profile/profile').then((m) => ({ default: m.ProfilePage })));
const ProfilePageNew = lazy(() => import('./pages/profile-new').then((m) => ({ default: m.ProfilePage })));
const SettingsPage = lazy(() => import('./pages/settings/settings-page').then((m) => ({ default: m.SettingsPage })));
const OrgStructurePage = lazy(() =>
  import('./pages/org-structure/org-structure').then((m) => ({ default: m.OrgStructurePage }))
);
const OrgStructureEditPage = lazy(() =>
  import('./pages/org-structure/org-structure-edit').then((m) => ({ default: m.OrgStructureEditComponent }))
);
const OrgStructureViewPage = lazy(() =>
  import('./pages/org-structure/org-structure-view').then((m) => ({ default: m.OrgStructureViewPage }))
);
const HoldingStructurePage = lazy(() =>
  import('./pages/holding-structure/holding-structure').then((m) => ({ default: m.HoldingStructurePage }))
);
const HrPage = lazy(() => import('./pages/hr/hr-page').then((m) => ({ default: m.HrPage })));
const GroupPage = lazy(() => import('./pages/hr/group-page').then((m) => ({ default: m.GroupPage })));
const FormulaEdit = lazy(() => import('./pages/hr/components/formula-edit').then((m) => ({ default: m.FormulaEdit })));
const Upgrade = lazy(() => import('./components/upgrade').then((m) => ({ default: m.UpgradeComponent })));

export function App() {
  const { pathname } = useLocation();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyle />
        <Toaster />
        <Security oktaAuth={oktaAuth}>
          <Suspense fallback={<Spinner variant="page" />}>
            <NotificationContextProvider>
              <DateFnsLocaleContextProvider>
                <FilterContextProvider>
                  <BreadcrumbProvider>
                    <Styled.App>
                      <Styled.Main>
                        <HeaderWrapper />
                        <Switch>
                          <Route exact path="/people/upgrade/:module" component={Upgrade} />
                          <Redirect from="people/:url*(/+)" to={pathname.slice(0, -1)} />
                          <Redirect exact from="/people" to="/people/org-structure" />
                          <Route path="/people/callback" component={LoginCallback} />
                          <Route path="/people/login" component={LoginPage} />
                          <Route path="/people/timesheets" component={TimesheetsPage} />
                          <Route path="/people/profile-old" component={ProfilePage} />
                          {/* <Redirect exact from="/people/profile" to="/people/profile/0" /> */}
                          <Route path="/people/profile/:id" component={ProfilePageNew} />
                          <Route path="/people/settings" component={SettingsPage} />
                          <Redirect
                            exact
                            from="/people/org-structure"
                            to={{
                              pathname: '/people/org-structure/all-employees?limit=10&page=0&status=ACTIVE',
                              state: { firstLoad: true },
                            }}
                          />
                          <Route exact path="/people/org-structure/:tab" component={OrgStructurePage} />
                          <Route path="/people/org-structure/edit/:id" exact component={OrgStructureEditPage} />
                          <Route path="/people/org-structure/view/:id" exact component={OrgStructureViewPage} />
                          <Route exact path="/people/holding-structure" component={HoldingStructurePage} />
                          <Redirect exact from="/people/hr" to="/people/hr/cnb" />
                          <Route exact path="/people/hr/:section" component={HrPage} />
                          <Route exact path="/people/hr/cnb/:id" component={GroupPage} />
                          <Route exact path="/people/hr/cnb/:id/formula/:formulaId" component={FormulaEdit} />

                          <Route path="/people/forbidden" component={ForbiddenPage} />
                          <Route path="*" component={ForbiddenPage} />
                        </Switch>
                      </Styled.Main>
                    </Styled.App>
                  </BreadcrumbProvider>
                </FilterContextProvider>
              </DateFnsLocaleContextProvider>
            </NotificationContextProvider>
          </Suspense>
        </Security>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
