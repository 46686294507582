import { Environment } from '@dms-hcms-web/api-interfaces';

export const environment: Environment = {
  production: true,
  oktaDomain: 'intra.darglobal.com',
  oktaClientId: '0oa78wxkd0LP8LI9V357',
  baseApiUrl: 'https://dms-hcms.dar.io/api',
  baseAssetsUrl: 'https://dms-hcms.dar.io/assets',
  bucketURL: 'https://dev-dms-hcms-bucket.s3.amazonaws.com',
  darTechCompanyId: 'bb4fc077-3c81-4419-a4d8-c2466aa12b42',
  notificationSocketUrl: 'wss://dms.dar.io/api/v1/web-socket/notification',
  baseUrl: 'https://dms.dar.io/api/v1',
  darHoldingId: 'bb4fc077-3c81-4419-a4d8-c2466aa12b42',
  commsApiUrl: 'https://dms-comms.dar.io/api',
  dmsBaseApi: 'https://dms.dar.io/api/v1',
  planningToolUrl: 'https://dms.dar.io/api/v1',
  roles: ['OWNER', 'ADMIN', 'MEMBER', 'GUEST'],
};
