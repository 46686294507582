/* eslint-disable @typescript-eslint/no-unused-vars */
import { toast } from '@dartech/dms-ui';

import { CreateOrgStructureDTO, SetStatusDTO, UpdateOrgStructureDTO, Workgroup } from '@dms-hcms-web/api-interfaces';

import { SagaReturnType, all, call, put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  addWorkgroup,
  create,
  deleteItem,
  deleteWorkgroup,
  duplicate,
  get,
  getExternalTreeStructure,
  getList,
  getSnapshotDetails,
  getSnapshotList,
  getStruttureShortInfo,
  setStatus,
  update,
} from '../../services/org-structure';
import { setFetchingFailure, setFetchingStart, setFetchingSuccess } from '../loading/loading-slice';
import {
  addWorkgroupFailure,
  addWorkgroupStart,
  addWorkgroupSuccess,
  createOrgStructureFailure,
  createOrgStructureStart,
  createOrgStructureSuccess,
  deleteOrgStructureFailure,
  deleteOrgStructureStart,
  deleteOrgStructureSuccess,
  deleteWorkgroupFailure,
  deleteWorkgroupStart,
  deleteWorkgroupSuccess,
  duplicateOrgStructureFailure,
  duplicateOrgStructureStart,
  duplicateOrgStructureSuccess,
  fetchItemFailure,
  fetchItemStart,
  fetchItemSuccess,
  fetchListFailure,
  fetchListStart,
  fetchListSuccess,
  fetchSnapshotDetailsFailure,
  fetchSnapshotDetailsStart,
  fetchSnapshotDetailsSuccess,
  fetchSnapshotListFailure,
  fetchSnapshotListStart,
  fetchSnapshotListSuccess,
  fetchTreeFailure,
  fetchTreeStart,
  fetchTreeSuccess,
  getShortInfoStart,
  getShortInfoSuccess,
  setOrgStructureStatusFailure,
  setOrgStructureStatusStart,
  setOrgStructureStatusSuccess,
  updateOrgStructureFailure,
  updateOrgStructureStart,
  updateOrgStructureSuccess,
  updateWorkgroupFailure,
  updateWorkgroupStart,
  updateWorkgroupSuccess,
} from './org-structure-slice';
import { transformToPlainWorkgroup } from './utils';

export function* createOrgStructureAsync(action: PayloadAction<CreateOrgStructureDTO>) {
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof create> = yield create(action.payload);
    const item = res?.data;
    yield put(createOrgStructureSuccess(item));
    yield put(setFetchingSuccess(action.type));
    toast.success('Successfully created!', { title: ' ' });
  } catch (error) {
    yield put(createOrgStructureFailure(error));
    yield put(setFetchingFailure(action.type));
    toast.error(error.message);
  }
}

export function* fetchOrgStructureListAsync(action: PayloadAction) {
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof getList> = yield getList();
    const list = res?.data;
    yield put(fetchListSuccess(list?.entities));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(fetchListFailure(error.message));
    yield put(setFetchingFailure(action.type));
  }
}

export function* fetchOrgStructureItemAsync(action: PayloadAction<string>) {
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof get> = yield get(action.payload);
    const item = res.data;
    yield put(fetchItemSuccess(item));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(fetchItemFailure(error.message));
    yield put(setFetchingFailure(action.type));
  }
}

export function* updateOrgStructureAsync(action: PayloadAction<{ id: string; data: Partial<UpdateOrgStructureDTO> }>) {
  const { id, data } = action.payload;
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof update> = yield update(id, data);
    console.log(res.data);
    yield put(updateOrgStructureSuccess(data.name));
    yield put(setFetchingSuccess(action.type));
    toast.success('Successfully updated!', { title: ' ' });
  } catch (error) {
    yield put(updateOrgStructureFailure(error));
    yield put(setFetchingFailure(action.type));
    toast.error(error.message);
  }
}

export function* duplicateOrgStructureAsync(action: PayloadAction<string>) {
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof duplicate> = yield duplicate(action.payload);
    yield put(duplicateOrgStructureSuccess(res.data));
    yield put(setFetchingSuccess(action.type));
    toast.success('Successfully duplicated!', { title: ' ' });
  } catch (error) {
    yield put(duplicateOrgStructureFailure(error));
    yield put(setFetchingFailure(action.type));
    toast.error(error.message);
  }
}

export function* deleteOrgStructureAsync(action: PayloadAction<string>) {
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof deleteItem> = yield deleteItem(action.payload);
    yield put(deleteOrgStructureSuccess(action.payload));
    yield put(setFetchingSuccess(action.type));
    toast.success('Successfully deleted!', { title: ' ' });
  } catch (error) {
    yield put(deleteOrgStructureFailure(error));
    yield put(setFetchingFailure(action.type));
    toast.error(error.message);
  }
}

export function* setOrgStructureStatusAsync(action: PayloadAction<{ id: string; data: SetStatusDTO }>) {
  const { id, data } = action.payload;
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof setStatus> = yield setStatus(id, data);
    yield put(setOrgStructureStatusSuccess(data.status));
    yield put(setFetchingSuccess(action.type));
    toast.success('Successfully published!', { title: ' ' });
  } catch (error) {
    yield put(setOrgStructureStatusFailure(error));
    yield put(setFetchingFailure(action.type));
    toast.error(error.message);
  }
}

export function* addWorkgroupAsync(action: PayloadAction<{ orgStructureId: string; data: Workgroup }>) {
  const { orgStructureId, data } = action.payload;
  const plainWorkgroup = transformToPlainWorkgroup(data);
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof addWorkgroup> = yield addWorkgroup(orgStructureId, plainWorkgroup);
    yield put(addWorkgroupSuccess(res.data));
    yield put(fetchItemStart(orgStructureId));
    yield put(setFetchingSuccess(action.type));
    toast.success('Successfully saved!', { title: ' ' });
  } catch (error) {
    yield put(addWorkgroupFailure(error));
    yield put(setFetchingFailure(action.type));
  }
}

export function* updateWorkgroupAsync(action: PayloadAction<{ orgStructureId: string; data: Workgroup }>) {
  const { orgStructureId, data } = action.payload;
  const plainWorkgroup = transformToPlainWorkgroup(data);

  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof addWorkgroup> = yield addWorkgroup(orgStructureId, plainWorkgroup);
    yield put(updateWorkgroupSuccess(res.data));
    yield put(setFetchingSuccess(action.type));
    toast.success('Successfully updated!', { title: ' ' });
  } catch (error) {
    yield put(updateWorkgroupFailure(error));
    yield put(setFetchingFailure(action.type));
  }
}

export function* deleteWorkgroupAsync(action: PayloadAction<{ id: string; workgroupId: string }>) {
  const { id, workgroupId } = action.payload;
  yield put(setFetchingStart(action.type));
  try {
    yield deleteWorkgroup(id, workgroupId);
    yield put(deleteWorkgroupSuccess(workgroupId));
    yield put(fetchItemStart(id));
    yield put(setFetchingSuccess(action.type));
    toast.success('Successfully deleted!', { title: ' ' });
  } catch (error) {
    yield put(deleteWorkgroupFailure(error));
    yield put(setFetchingFailure(action.type));
  }
}

export function* fetchSnapshotListAsync(action: PayloadAction<string>) {
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof getSnapshotList> = yield getSnapshotList();
    const list = res?.data;
    yield put(fetchSnapshotListSuccess(list?.entities));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(fetchSnapshotListFailure(error.message));
    yield put(setFetchingFailure(action.type));
  }
}

export function* fetchSnapshotDetailsAsync(action: PayloadAction<string>) {
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof getSnapshotDetails> = yield getSnapshotDetails(action.payload);
    yield put(fetchSnapshotDetailsSuccess(res?.data));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(fetchSnapshotDetailsFailure(error));
    yield put(setFetchingFailure(action.type));
  }
}

export function* fetchTreeStructureAsync(action: PayloadAction<string>) {
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof getExternalTreeStructure> = yield getExternalTreeStructure(action.payload);
    const data = res?.data;
    yield put(fetchTreeSuccess(data));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(fetchTreeFailure(error.message));
    yield put(setFetchingFailure(action.type));
  }
}

export function* fetchStructureShortInfo(action: PayloadAction<any>) {
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof getStruttureShortInfo> = yield getStruttureShortInfo();
    const data = res?.data?.content;
    yield put(getShortInfoSuccess(data));
  } catch (error) {
    yield put(fetchTreeFailure(error.message));
    yield put(setFetchingFailure(action.type));
  }
}

export function* onCreateOrgStructureStart() {
  yield takeLatest(createOrgStructureStart.type, createOrgStructureAsync);
}

export function* onFetchOrgStructureListStart() {
  yield takeLatest(fetchListStart.type, fetchOrgStructureListAsync);
}

export function* onFetchOrgStructureItemStart() {
  yield takeLatest(fetchItemStart.type, fetchOrgStructureItemAsync);
}

export function* onUpdateOrgStructureStart() {
  yield takeLatest(updateOrgStructureStart.type, updateOrgStructureAsync);
}

export function* onDeleteOrgStructureStart() {
  yield takeLatest(deleteOrgStructureStart.type, deleteOrgStructureAsync);
}

export function* onDuplicateOrgStructureStart() {
  yield takeLatest(duplicateOrgStructureStart.type, duplicateOrgStructureAsync);
}

export function* onSetOrgStructureStatusStart() {
  yield takeLatest(setOrgStructureStatusStart.type, setOrgStructureStatusAsync);
}

export function* onAddWorkgroupStart() {
  yield takeLatest(addWorkgroupStart.type, addWorkgroupAsync);
}

export function* onUpdateWorkgroupStart() {
  yield takeLatest(updateWorkgroupStart.type, updateWorkgroupAsync);
}

export function* onDeleteWorkgroupStart() {
  yield takeLatest(deleteWorkgroupStart.type, deleteWorkgroupAsync);
}

export function* onFetchSnapshotListStart() {
  yield takeLatest(fetchSnapshotListStart.type, fetchSnapshotListAsync);
}

export function* onFetchSnapshotDetailsStart() {
  yield takeLatest(fetchSnapshotDetailsStart.type, fetchSnapshotDetailsAsync);
}

export function* onFetchTreeStructureStart() {
  yield takeLatest(fetchTreeStart.type, fetchTreeStructureAsync);
}

export function* onFetchStructureShortInfoStart() {
  yield takeLatest(getShortInfoStart.type, fetchStructureShortInfo);
}

export function* orgStructureSagas() {
  yield all([
    call(onCreateOrgStructureStart),
    call(onFetchOrgStructureListStart),
    call(onFetchOrgStructureItemStart),
    call(onUpdateOrgStructureStart),
    call(onDuplicateOrgStructureStart),
    call(onDeleteOrgStructureStart),
    call(onSetOrgStructureStatusStart),
    call(onAddWorkgroupStart),
    call(onUpdateWorkgroupStart),
    call(onDeleteWorkgroupStart),
    call(onFetchSnapshotListStart),
    call(onFetchSnapshotDetailsStart),
    call(onFetchTreeStructureStart),
    call(onFetchStructureShortInfoStart),
  ]);
}
