import { createSlice } from '@reduxjs/toolkit';

import reducers from './comments-reducer';

export interface CommentsState {
  list: any[];
  errorMessage: string;
}

export const initialState: CommentsState = {
  list: [],
  errorMessage: null,
};

export const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers,
});

export const {
  fetchCommentsStart,
  fetchCommentsSuccess,
  fetchCommentsFailure,
  addCommentStart,
  addCommentSuccess,
  addCommentFailure,
} = commentsSlice.actions;

export default commentsSlice.reducer;
