import { authService } from '@dar-dms/topbar';
import { ErrorBoundary } from '@dar-dms/utils';
import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { App } from './app/app';
import { store } from './app/redux/store';

export const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
});

const Root = () => {
  const [authLoaded, setAuthLoaded] = useState(false);

  useEffect(() => {
    if (authService) {
      const sub = authService.loggedIn.subscribe((auth) => {
        if (auth) {
          setAuthLoaded(true);
        }
      });
      return () => {
        sub.unsubscribe();
      };
    }
  }, []);

  return (
    <ErrorBoundary moduleName="hcms-web">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Provider store={store}>{authLoaded && <App />}</Provider>
        </BrowserRouter>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export { Root };
