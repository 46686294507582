import React, { createContext, useCallback, useEffect, useState } from 'react';

import { Notification } from '@dms-hcms-web/api-interfaces';

import { environment } from '../../environments/environment';
import { notificationService } from '../services/notifications';
import { SocketClient } from '../utils/websocket';

// TODO: seems like deprecated. Remove after research
interface NotificationsContextState {
  socket: SocketClient;
  notifications: Record<string, Notification[]>;
  readNotifications: (ids: string[]) => void;
}

interface ProviderProps {
  children: React.ReactNode;
}

const userId = JSON.parse(localStorage.getItem('profile'))?.email.split('@')[0].toLowerCase();

export const NotificationsContext = createContext<Partial<NotificationsContextState>>({});

export const NotificationContextProvider = ({ children }: ProviderProps) => {
  const [notifications, setNotifications] = useState<Record<string, Notification[]>>(null);

  const addNotifications = useCallback((messages: Notification[]) => {
    if (messages) {
      setNotifications((prevState) => {
        const newState = { ...prevState };
        messages.map((message) => {
          const type = message.payload?.entityType;
          if (type && !message.read) {
            if (newState[type]) {
              newState[type].push(message);
            } else {
              newState[type] = [message];
            }
          }
        });

        return newState;
      });
    }
  }, []);

  const readNotifications = useCallback((ids: string[]) => {
    for (let i = 0; i < ids.length; i++) {
      notificationService.updateNotificationStatus(ids[i], true);
    }

    setNotifications((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((type) => {
        newState[type] = newState[type].filter((notification) => !ids.includes(notification.notificationId));
      });

      return newState;
    });
  }, []);

  // useEffect(() => {
  //   const receiveHandler = (message) => {
  //     addNotifications([message]);
  //   };

  //   const socket = SocketClient.getInstance({
  //     url: environment.notificationSocketUrl,
  //   });

  //   socket.open(userId);
  //   socket.eventEmitter.on('info', receiveHandler);

  //   return () => {
  //     socket.eventEmitter.off('info', receiveHandler);
  //     socket.close();
  //   };
  // }, []);

  // useEffect(() => {
  //   notificationService.getAllNotifications(userId).then((res) => {
  //     addNotifications(res.data);
  //   });
  // }, []);

  return (
    <NotificationsContext.Provider value={{ notifications, readNotifications }}>
      {children}
    </NotificationsContext.Provider>
  );
};
