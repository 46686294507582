import { Contract } from '@dms-hcms-web/api-interfaces';

import { getCompany } from '@shared/company';

import { environment } from '@environment';

import httpClient from './http';

export const getContract = ({ id }: Contract.GetContract.QueryParams) => {
  const company = getCompany();
  return httpClient.get<Contract.GetContract.ResponseDto>(`${environment.baseApiUrl}/contract/${id}`, {
    params: {
      holdingId: company.holdingId,
    },
  });
};

export const createContract = (contract: Contract.CreateContract.RequestDto) => {
  const company = getCompany();
  return httpClient.post<Contract.CreateContract.ResponseDto>(`${environment.baseApiUrl}/contract`, contract, {
    params: {
      holdingId: company.holdingId,
    },
  });
};
export const getSalary = ({ employeeId }: Contract.GetSalary.QueryParams) => {
  const company = getCompany();
  return httpClient.get<Contract.GetSalary.ResponseDto>(`${environment.baseApiUrl}/contract/salary/${employeeId}`, {
    params: {
      holdingId: company.holdingId,
    },
  });
};
export const getHistory = ({ employeeId }: Contract.GetHistory.QueryParams) => {
  const company = getCompany();
  return httpClient.get<Contract.GetHistory.QueryParams>(`${environment.baseApiUrl}/contract/history/${employeeId}`, {
    params: {
      holdingId: company.holdingId,
    },
  });
};
export const getContractList = (body: Contract.GetContractList.RequestDto) => {
  return httpClient
    .post<Contract.GetContractList.ResponseDto>(`${environment.baseUrl}/hcms/main-api/contracts/list`, body)
    .then((res) => res.data);
};
