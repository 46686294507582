import { authService } from '@dar-dms/topbar';
import { Structure, Workspace } from '@dms-hcms-web/api-interfaces';

let company: Structure.StructureWorkspaceDto;

export const getCompany = () => {
  if (localStorage.getItem('workspace') === 'undefined' || !localStorage.getItem('workspace')) {
    return {} as Structure.StructureWorkspaceDto;
  } else {
    return JSON.parse(localStorage.getItem('workspace'));
  }
};

authService.loggedIn.subscribe((auth) => {
  if (auth) {
    if (localStorage.getItem('workspace') === 'undefined' || !localStorage.getItem('workspace')) {
      company = {} as Structure.StructureWorkspaceDto;
    } else {
      company = JSON.parse(localStorage.getItem('workspace'));
    }
  }
});

export { company };
