/* eslint-disable react-hooks/rules-of-hooks */
import { Structure } from '@dms-hcms-web/api-interfaces';

import { useWorkspace } from '@hooks/useWorkspace';

import { environment } from '@environment';

import httpClient from './http';

export const addGuestsToWorkspace = (data: Structure.AddGuests.RequestDto) => {
  return httpClient.post<Structure.AddGuests.ResponseDto>(
    `${environment.baseUrl}/hcms/main-api/structure/add-guests`,
    data
  );
};
export const getStaffListFilters = (field: Structure.GetStaffListFilters.QueryParams) => {
  const [company] = useWorkspace();
  return httpClient.get<Structure.GetStaffListFilters.ResponseDto>(
    `${environment.baseApiUrl}/structure/employee/list/filters`,
    {
      params: {
        companyId: company.id,
        field,
      },
    }
  );
};

export const getStructureUser = (data: Structure.GetStructureUser.QueryParams) => {
  return httpClient
    .get<Structure.GetStructureUser.ResponseDto>(`${environment.baseUrl}/hcms/main-api/structure`, {
      params: data,
    })
    .then((res) => res.data);
};

export const getCompanyUsers = (params: Structure.GetStructureUsers.QueryParams = {}) => {
  const [{ id: workspaceId }] = useWorkspace();
  return httpClient
    .get<Structure.GetStructureUsers.ResponseDto>(
      `${environment.baseUrl}/hcms/main-api/structure/workspaces/${workspaceId}/users-and-teams`,
      { params: { ...params } }
    )
    .then((res) => res.data);
};

export const getWorkspaceStaffList = (params: Structure.GetWorkspaceStaffList.QueryParams = {}) => {
  const [{ id: workspaceId }] = useWorkspace();
  return httpClient
    .get<Structure.GetWorkspaceStaffList.ResponseDto>(
      `${environment.baseUrl}/hcms/main-api/structure/workspaces/${workspaceId}/staff-list`,
      { params: { ...params } }
    )
    .then((res) => res.data);
};

export const getWorkspaceEmployees = (params: Structure.GetWorkspaceEmployees.QueryParams = {}) => {
  const [{ id: workspaceId }] = useWorkspace();
  return httpClient
    .get<Structure.GetWorkspaceEmployees.ResponseDto>(
      `${environment.baseUrl}/hcms/main-api/structure/${workspaceId}/employees`,
      { params: { ...params } }
    )
    .then((res) => res.data);
};

export const checkIfUserIsSupervisor = ({ employeeId }: { employeeId: string }) => {
  const [{ holdingId }] = useWorkspace();
  return httpClient
    .get<boolean>(`${environment.baseUrl}/hcms/main-api/structure/workspaces/${holdingId}/isSupervisor/${employeeId}`)
    .then((res) => res.data);
};
