import { Locale, setDefaultOptions } from 'date-fns';
import { de, enGB, id, kk, ru } from 'date-fns/locale';
import 'dayjs/locale/de';
import 'dayjs/locale/id';
import 'dayjs/locale/kk';
import 'dayjs/locale/ru';
import React, { FC, createContext, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const locales = {
  en: enGB,
  ru: ru,
  kz: kk,
  id: id,
  de: de,
};

export const DateFnsLocaleContext = createContext<{ locale: Locale }>({ locale: undefined });
export const useDateFnsLocaleContext = () => useContext(DateFnsLocaleContext);

export const DateFnsLocaleContextProvider: FC = ({ children }) => {
  const { i18n } = useTranslation();

  const locale: Locale = useMemo(() => {
    return locales[i18n.language];
  }, [i18n.language]);

  useEffect(() => {
    setDefaultOptions({ locale });
  }, [locale]);

  return <DateFnsLocaleContext.Provider value={{ locale }}>{children}</DateFnsLocaleContext.Provider>;
};
