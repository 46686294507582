import { theme as rootTheme } from '@dartech/dms-ui';
import { adaptV4Theme, createTheme } from '@mui/material/styles';

const theme = createTheme(adaptV4Theme({ ...rootTheme }));

theme.palette.primary[700] = '#039be6';

theme.components = {
  ...theme.components,
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        width: 'auto',
      },
    },
  },
  MuiBadge: {
    styleOverrides: {
      invisible: {
        display: 'none',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        backgroundColor: '#387E7F',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'unset',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      text: {
        '& path': {
          fill: '#1B74B4',
        },
      },
      root: {
        textTransform: 'unset',
        padding: '8px',
      },
      startIcon: {
        marginLeft: '0px',
      },
      textPrimary: {
        '&': {
          color: 'rgb(27, 116, 180)',
          backgroundColor: 'transparent',
          border: '1px solid transparent',
          ':hover': {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
          },
        },
      },
      outlinedSecondary: {
        color: '#1D2B36',
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      root: {
        ':before': {
          borderBottom: 'unset',
        },
        ':after': {
          borderBottom: 'unset',
        },
        '&&:hover': {
          ':before': {
            borderBottom: 'unset',
          },
          ':after': {
            borderBottom: 'unset',
          },
        },
      },
      input: {
        padding: '0px',
      },
    },
  },

  MuiFormLabel: {
    styleOverrides: {
      asterisk: {
        color: 'red',
      },
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        '&.MuiMenuItem-root:hover': {
          backgroundColor: 'rgb(245, 248, 250)',
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&&': {
          '&.Mui-selected': {
            backgroundColor: 'rgba(255, 255, 255, 0.16)',
          },
        },
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      input: {
        height: '40px',
        border: '1px solid rgba(38, 40, 66, 0.12)',
        '& .MuiTablePagination-selectIcon': {
          color: 'black',
        },
      },
      menuItem: {
        '&:hover': {
          backgroundColor: '#F5F8FA',
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        '&.Mui-focused': {
          color: 'rgb(70, 85, 100)',
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: '#1D2B36',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: '10px',
        },
        '& .MuiSelect-icon': {
          color: '#1D2B36',
        },
        '& .MuiAutocomplete-endAdornment': {
          right: '7px',
        },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      endAdornment: {
        '& .MuiSvgIcon-root.MuiSvgIcon-root ': {
          color: '#1D2B36',
        },
      },
      listbox: {
        '& .MuiAutocomplete-option': {
          '&.Mui-focused': {
            backgroundColor: 'rgb(245, 248, 250) !important',
          },
        },
      },
    },
  },
  MuiButtonGroup: {
    styleOverrides: {
      root: {
        '& .HCMS-MuiButton-outlinedSecondary': {
          color: '#1D2B36',
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      action: {
        '& svg path': {
          fill: '#fff',
        },
      },
    },
  },
};

export default theme;
